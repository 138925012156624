import React from 'react'
import {ReviewWithImageWrapper} from './ReviewWithImage.styles'
import {IReviewWithImage} from './ReviewWithImage.interface'
import {
  OptimizedImage,
  Typography,
  TypographyVariants,
  ReviewRatingStar,
  Media,
  useDeviceTypeContext,
} from '@web-components'

const ReviewWithImage = (props: IReviewWithImage) => {
  const {data} = props || {}
  const {content, quoteImage, media} = data || {}
  const {isMobile = false} = useDeviceTypeContext()

  return (
    <ReviewWithImageWrapper>
      <Media
        media={media}
        customClassName="background-image"
        desktopViewWidth="THIRD"
        mobileViewWidth="THIRD"
      />
      <div className="quotation-mark-holder">
        <OptimizedImage
          source={quoteImage}
          customClassName="quotation-mark"
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
        />
      </div>
      <div className="review-content-wrapper">
        <div className="review-content-info">
          <Typography
            variant={
              isMobile
                ? TypographyVariants.HEADING_MEDIUM_REGULAR
                : TypographyVariants.HEADING_SMALL_REGULAR
            }
          >
            {content?.info}
          </Typography>
        </div>
        <div className="media-info-divider"></div>
        <div className="review-info-bottom">
          <div className="review-info-name-title">
            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {content?.bottomContent?.name}
            </Typography>
            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {content?.bottomContent?.designation}
            </Typography>
          </div>
          <div className="rating">
            <ReviewRatingStar
              count={5}
              value={5}
              size={22}
              className="ratingStyle"
              color="#F6CA4C"
              activeColor="#F6CA4C"
            />
          </div>
        </div>
      </div>
    </ReviewWithImageWrapper>
  )
}

export default ReviewWithImage
