import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {MediaWithInfoCardsWrapper} from './MediaWithInfoCards.styles'
import {Carousel, TitleWithLabel} from '@web-components'
import MediaWithInfoCard from './MediaWithInfo/MediaWithInfoCard'
import {IItem, IMediaWithInfoCards} from './MediaWithInfoCards.interface'

const MediaWithInfoCards = (props: BaseWidgetProps<IMediaWithInfoCards>) => {
  const {type, id, layout, widgetData, header} = props

  const {items = [], carouselConfig} = widgetData || {}

  const {
    aspectRatio = 1,
    showDots = false,
    showArrows,
    slidesToShow,
    slidesToShowDesktop,
    autoSlide,
    autoSlideInterval,
    infinite,
    slidesToScroll,
    arrowPosition = 'inside',
  } = carouselConfig || {}

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithInfoCardsWrapper>
        <Carousel
          showDots={showDots}
          showArrows={showArrows}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          autoSlide={autoSlide}
          autoSlideInterval={autoSlideInterval}
          infinite={infinite}
          slidesToScroll={slidesToScroll}
          arrowPosition={arrowPosition}
        >
          {items.map((item: IItem, index: number) => {
            return (
              <MediaWithInfoCard
                key={index}
                data={item}
                aspectRatio={aspectRatio}
              />
            )
          })}
        </Carousel>
      </MediaWithInfoCardsWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithInfoCards
