import React, {useCallback, useState} from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {
  CouponContainer,
  MainOffer,
  CopyButton,
  Row,
} from './OfferCouponCard.styles'
import {
  Copy,
  Typography,
  TypographyVariants,
  Clipboard,
  Check,
} from '@web-components'
import {logger} from '../../utils'
import {OfferCouponCardProps} from './OfferCouponCard.interface'

const OfferCouponCard = (props: BaseWidgetProps<OfferCouponCardProps>) => {
  const {layout, type, widgetData, id} = props
  const {
    offerText = '',
    subText = '',
    couponCode = '',
    useCodeText = ' ',
    buttonText = '',
    copiedButtonText = '',
  } = widgetData || {}

  const [copied, setCopied] = useState(false)

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(couponCode)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      logger(err)
    }
  }, [couponCode])

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <CouponContainer>
        <Row>
          <MainOffer>
            <Clipboard size="18" />

            <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
              {offerText}
            </Typography>
          </MainOffer>
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {useCodeText}{' '}
            <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
              {couponCode}
            </Typography>
          </Typography>
        </Row>

        <Row>
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {subText}
          </Typography>
          <CopyButton onClick={handleCopy}>
            {copied ? <Check size="20" /> : <Copy size="20" />}

            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {copied ? copiedButtonText : buttonText}
            </Typography>
          </CopyButton>
        </Row>
      </CouponContainer>
    </BaseWidgetComponent>
  )
}

export default OfferCouponCard
