import React from 'react'
import MediaTextBlockCard from './MediaTextBlockCard/MediaTextBlockCard'
import {BaseWidget, BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {MediaSection} from './MediaTextBlock.interface'

const MediaTextBlock = (props: BaseWidgetProps<MediaSection>) => {
  const {widgetData, type, layout, id, header} = props
  const {items = []} = widgetData || {}

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />

      {items.map((item, index: number) => (
        <MediaTextBlockCard
          cardData={item}
          key={`mediaTextBlock-card-${index}`}
        />
      ))}
    </BaseWidgetComponent>
  )
}

export default MediaTextBlock
