import {useCartStore} from '@mosaic-wellness/redux-action-library'
import {useCartLoadingContext} from 'src/components/shared/src/ComponentsV2/Context/CartUpdatingContext'

import noop from 'lodash/noop'
import useProductCardActions from '../../hooks/useProductCardActions'
import ProductCardSlider from './ProductCardSlider'
import {ProductCardSliderProps} from './ProductCardSlider.interface'

const ProductCardSliderContainer = (
  props: BaseWidgetProps<ProductCardSliderProps>
) => {
  const {type, widgetData, header, layout, id} = props || {}

  const {isCartUpdating, itemBeingAddedToCart} = useCartLoadingContext()
  const {cart} = useCartStore()
  const {checkoutInfo} = cart || {}
  const {items} = checkoutInfo || {}

  const {source = ''} = widgetData || {}

  const {
    handleCardClick = noop,
    handleCtaClick = noop,
    handleUpdateCartItem = noop,
  } = useProductCardActions({source: source})

  return (
    <ProductCardSlider
      id={id}
      type={type}
      header={header}
      layout={layout}
      widgetData={{
        ...widgetData,
        isCartUpdating,
        itemBeingAddedToCart,
        cartItems: items,
        handleCardClick,
        handleCtaClick,
        handleUpdateCartItem,
      }}
    />
  )
}

export default ProductCardSliderContainer
