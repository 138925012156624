import styled from 'styled-components'

export const RatingsAndReviewsContainer = styled.div`
  width: 100%;

  .view-all-reviews-button {
    outline: 0;
    border: 0;
    background: transparent;
    width: 100%;
    padding: 12px 0;
    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-category-primary-color-500);
    margin-top: var(--dls-size-16);
    color: var(--dls-category-primary-color-500);
    display: flex;
    gap: var(--dls-size-4);
    align-items: center;
    justify-content: center;
  }

  .chevron-icon {
    transform: rotate(270deg);
  }

  .top-review-label-container {
    padding: var(--dls-size-16) 0;
    border-top: 1px solid var(--dls-divider-light-color);
    border-bottom: 1px solid var(--dls-divider-light-color);
  }
  .top-reviews-label {
    width: 100%;
    color: var(--dls-primary-text-colors);
  }
`
