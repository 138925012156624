import styled from 'styled-components'

export const CtaButtonContainer = styled.div`
  padding: 0 var(--dls-size-32);
  width: 100%;
  display: flex;
  gap: var(--dls-size-8);
  justify-content: center;
  align-items: center;

  .ROUNDED {
    border-radius: var(--dls-size-40);
  }
  .SQUARED {
    border-radius: var(--dls-size-4);
  }
  &[data-issticky='true'] {
    padding: var(--dls-size-8) var(--dls-size-16);
    max-width: 500px;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    z-index: 2;
  }
  &[data-isstickyfooter='true'] {
    padding: var(--dls-size-16);
    max-width: 500px;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    z-index: 2;
    background-color: var(--dls-white-color);
    border-top: 1px solid var(--dls-divider-light-color);
  }
`
