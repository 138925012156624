import React from 'react'
import {CalloutWithImageContainer} from './CalloutWithImage.styles'
import {ICalloutWithImageProps} from './CalloutWithImage.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'

function CalloutWithImage(props: ICalloutWithImageProps) {
  const {id, type, widgetData, layout} = props
  const {
    image = '',
    altText = 'icon',
    text = '',
    variant = 'INFO',
  } = widgetData || {}

  if (!text) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <CalloutWithImageContainer className={variant}>
        {!!image && (
          <div className="callout-image-container">
            <OptimizedImage
              source={image}
              aspectHeight="42"
              aspectWidth="42"
              desktopViewWidth="SMALL"
              mobileViewWidth="SMALL"
              altText={altText}
            />
          </div>
        )}
        <Typography
          variant={TypographyVariants.SUBHEADING_BOLD}
          customClassName="callout-text"
        >
          {text}
        </Typography>
      </CalloutWithImageContainer>
    </BaseWidgetComponent>
  )
}

export default CalloutWithImage
