import {useCallback, useEffect, useRef, useState} from 'react'

export const useStickyBuyOptions = () => {
  const [showStickyFooter, setShowStickyFooter] = useState(false)
  const buttonGroupRef = useRef()
  const registerSection = useCallback(() => {
    let observer
    const elementToObserve = buttonGroupRef.current
    if (elementToObserve && window.IntersectionObserver) {
      const intersectionObserverOptions = {
        rootMargin: '0px 0px 150px 0px',
        threshold: 1,
      }
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          //  DO something
          if (entry.isIntersecting) {
            setShowStickyFooter(false)
          } else {
            setShowStickyFooter(true)
          }
        })
      }, intersectionObserverOptions)

      observer.observe(elementToObserve)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    registerSection()
  }, [registerSection])
  return {
    buttonGroupRef,
    showStickyFooter,
  }
}
