import styled from 'styled-components'

export const MediaWithProgressSliderWrapper = styled.div`
  padding: var(--dls-size-16);

  @media (min-width: 768px) {
    padding: var(--dls-size-32);
  }
  margin: auto;
`
