import React, {useCallback} from 'react'
import {ImageCtaGridViewWrapper} from './ImageCtaGridView.styles'
import {
  OptimizedImage,
  RightTiltedArrow,
  Typography,
  TypographyVariants,
  useDeviceTypeContext,
} from '@web-components'
import {useGenericActions} from '../../hooks'
import {BaseWidget, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'

const ImageCtaGridView = (props: BaseWidget) => {
  const {widgetData, type, layout, id, header} = props
  const {leftSection, rightSection, centerSection} = widgetData || {}
  const {isMobile = false} = useDeviceTypeContext()
  // const isMobile = false
  const {handleGenericActions} = useGenericActions()
  const handleCardClick = useCallback(
    (actions: GenericAction[]) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id]
  )

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <ImageCtaGridViewWrapper>
        {isMobile ? (
          <>
            <div className="center-section">
              <div className="center-content">
                <OptimizedImage
                  source="https://i.mscwlns.co/media/misc/others/Screenshot%202025-02-13%20at%201.41.32%E2%80%AFPM_2y54pz.png?tr=w-600"
                  customClassName="center-image"
                />
              </div>
            </div>
            <div className="left-section">
              <div
                className="cta-background-image-container"
                onClick={() => handleCardClick(leftSection.card1.actions)}
              >
                <div className="arrow-container">
                  <RightTiltedArrow size="20" color="var(--dls-black-color)" />
                </div>
                <OptimizedImage
                  source={leftSection.card1.imageUrl}
                  desktopViewWidth="THIRD"
                  mobileViewWidth="THIRD"
                  customClassName="cta-background-image"
                />
                <div className="bottom-content">
                  <Typography variant={TypographyVariants.SUBHEADING_REGULAR}>
                    {leftSection.card1.label}
                  </Typography>
                </div>
              </div>
              <div
                className="card-cta"
                onClick={() => handleCardClick(leftSection.card2.actions)}
              >
                <OptimizedImage
                  source={leftSection.card2.imageUrl}
                  aspectHeight="THIRD"
                  desktopViewWidth="THIRD"
                  customClassName="card-cta-image"
                />
              </div>
              <div
                className="card-cta"
                onClick={() => handleCardClick(leftSection.card3.actions)}
              >
                <OptimizedImage
                  source={leftSection.card3.imageUrl}
                  aspectHeight="THIRD"
                  desktopViewWidth="THIRD"
                  customClassName="card-cta-image"
                />
              </div>
            </div>
            <div className="right-section">
              <div
                className="card-cta"
                onClick={() => handleCardClick(rightSection.card1.actions)}
              >
                <OptimizedImage
                  source={rightSection.card1.imageUrl}
                  aspectHeight="THIRD"
                  desktopViewWidth="THIRD"
                  customClassName="card-cta-image"
                />
              </div>
              <div
                className="cta-background-image-container-right"
                onClick={() => handleCardClick(rightSection.card2.actions)}
              >
                <div className="arrow-container">
                  <RightTiltedArrow size="20" color="var(--dls-black-color)" />
                </div>
                <OptimizedImage
                  source={rightSection.card2.imageUrl}
                  desktopViewWidth="THIRD"
                  mobileViewWidth="THIRD"
                  customClassName="cta-background-image"
                />
                <div className="bottom-content-right">
                  <Typography variant={TypographyVariants.SUBHEADING_REGULAR}>
                    Know Your Body
                  </Typography>
                </div>
              </div>
              <div
                className="card-cta"
                onClick={() => handleCardClick(rightSection.card3.actions)}
              >
                <OptimizedImage
                  source={rightSection.card3.imageUrl}
                  aspectHeight="THIRD"
                  desktopViewWidth="THIRD"
                  customClassName="card-cta-image"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {' '}
            <div className="left-section">
              <div
                className="cta-background-image-container"
                onClick={() => handleCardClick(leftSection.card1.actions)}
              >
                <div className="arrow-container">
                  <RightTiltedArrow size="20" color="var(--dls-black-color)" />
                </div>
                <OptimizedImage
                  source={leftSection.card1.imageUrl}
                  desktopViewWidth="THIRD"
                  mobileViewWidth="THIRD"
                  customClassName="cta-background-image"
                />
                <div className="bottom-content">
                  <Typography variant={TypographyVariants.SUBHEADING_REGULAR}>
                    {leftSection.card1.label}
                  </Typography>
                </div>
              </div>
              <div
                className="card-cta"
                onClick={() => handleCardClick(leftSection.card2.actions)}
              >
                <OptimizedImage
                  source={leftSection.card2.imageUrl}
                  aspectHeight="THIRD"
                  desktopViewWidth="THIRD"
                  customClassName="card-cta-image"
                />
              </div>
              <div
                className="card-cta"
                onClick={() => handleCardClick(leftSection.card3.actions)}
              >
                <OptimizedImage
                  source={leftSection.card3.imageUrl}
                  aspectHeight="THIRD"
                  desktopViewWidth="THIRD"
                  customClassName="card-cta-image"
                />
              </div>
            </div>
            <div className="center-section">
              <div className="center-content">
                <OptimizedImage
                  source={centerSection.imageUrl}
                  customClassName="center-image"
                />
              </div>
            </div>
            <div className="right-section">
              <div
                className="card-cta"
                onClick={() => handleCardClick(rightSection.card1.actions)}
              >
                <OptimizedImage
                  source={rightSection.card1.imageUrl}
                  aspectHeight="THIRD"
                  desktopViewWidth="THIRD"
                  customClassName="card-cta-image"
                />
              </div>
              <div
                className="cta-background-image-container-right"
                onClick={() => handleCardClick(rightSection.card2.actions)}
              >
                <div className="arrow-container">
                  <RightTiltedArrow size="20" color="var(--dls-black-color)" />
                </div>
                <OptimizedImage
                  source={rightSection.card2.imageUrl}
                  desktopViewWidth="THIRD"
                  mobileViewWidth="THIRD"
                  customClassName="cta-background-image"
                />
                <div className="bottom-content-right">
                  <Typography variant={TypographyVariants.SUBHEADING_REGULAR}>
                    {rightSection.card2.label}
                  </Typography>
                </div>
              </div>
              <div
                className="card-cta"
                onClick={() => handleCardClick(rightSection.card3.actions)}
              >
                <OptimizedImage
                  source={rightSection.card3.imageUrl}
                  aspectHeight="THIRD"
                  desktopViewWidth="THIRD"
                  customClassName="card-cta-image"
                />
              </div>
            </div>
          </>
        )}
      </ImageCtaGridViewWrapper>
    </BaseWidgetComponent>
  )
}

export default ImageCtaGridView
