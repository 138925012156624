import {MediaWithFooterSliderContainer} from './MediaWithFooterSlider.styles'
import {BaseWidgetComponent} from '../BaseWidget'
import {IMediaWithFooterSliderProps} from './MediaWithFooterSlider.interface'
import isEmpty from 'lodash/isEmpty'
import MediaWithFooterCard from './MediaWithFooterCard/MediaWithFooterCard'
import {
  Media,
  Modal,
  Slider,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useCallback, useState} from 'react'
import {useGenericActions} from '@web-core'
import {GenericAction} from '@mosaic-wellness/fe-types'

function MediaWithFooterSlider(props: IMediaWithFooterSliderProps) {
  const {type, widgetData, header, layout, id} = props || {}
  const {sliderConfig, items, modalEnabled = false} = widgetData || {}
  const {
    aspectRatio = 1,
    slidesToShow = 1,
    showPeek = true,
    peekVariant = 'LARGE',
  } = sliderConfig || {}

  const [isModalOpen, setIsModalOpen] = useState<{
    isOpen: boolean
    cardData: any
  }>({
    isOpen: false,
    cardData: null,
  })
  const {cardData} = isModalOpen
  const {media, description} = cardData || {}

  const {handleGenericActions} = useGenericActions()

  const handleAction = useCallback(
    (actions: GenericAction[]) => {
      handleGenericActions(actions)
    },
    [handleGenericActions]
  )

  const onCardClick = useCallback(
    (cardData: any) => {
      if (!modalEnabled) {
        return
      }

      setIsModalOpen((data) => ({
        ...data,
        isOpen: !data.isOpen,
        cardData,
      }))
    },
    [modalEnabled]
  )

  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithFooterSliderContainer>
        <Slider
          slidesToShow={slidesToShow}
          aspectRatio={aspectRatio}
          itemCount={items.length}
          isMobileView={layout?.isSupportedOnlyForMobile}
          showPeek={showPeek}
          peekVariant={peekVariant}
        >
          {() =>
            items.map((item) => {
              const {header, media, description, readMoreLabel, miniCtas} = item
              return (
                <MediaWithFooterCard
                  key={item.header}
                  onCardClick={onCardClick}
                  handleAction={handleAction}
                  header={header}
                  media={media}
                  description={description}
                  readMoreLabel={readMoreLabel}
                  miniCtas={miniCtas}
                  aspectRatio={aspectRatio}
                />
              )
            })
          }
        </Slider>
        {isModalOpen.isOpen && (
          <Modal
            showModal={isModalOpen.isOpen}
            onClose={() => onCardClick(null)}
            additionalContentClass="modal-content"
          >
            <Media
              media={media}
              desktopViewWidth={'THIRD'}
              mobileViewWidth={'THIRD'}
              aspectRatio={aspectRatio}
              customClassName="expanded-image"
            />
            <div className="expanded-description">
              <Typography variant={TypographyVariants.TAG_REGULAR}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </Typography>
            </div>
          </Modal>
        )}
      </MediaWithFooterSliderContainer>
    </BaseWidgetComponent>
  )
}

export default MediaWithFooterSlider
