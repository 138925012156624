import styled from 'styled-components'

export const ReviewCardContainer = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-4);
  border-bottom: 1px solid var(--dls-divider-light-color);

  .rating-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--dls-primary-text-colors);
  }
  .ratings-stars {
    display: flex;
  }

  .review-title,
  .review-body,
  .author {
    color: var(--dls-primary-text-colors);
    display: flex;
    align-items: center;
    gap: var(--dls-size-4);
  }

  .date-created {
    color: var(--dls-secondary-text-colors);
  }
  .images-container {
    display: flex;
    padding: var(--dls-size-8) 0;
    gap: var(--dls-size-8);
    margin-bottom: var(--dls-size-4);
    overflow-x: scroll;

    -webkit-overflow-scrolling: touch; /* Needed to work on iOS Safari */
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .review-image {
    object-fit: cover;
    border-radius: 10px;
    flex-shrink: 0;
  }

  .modal-content {
    border-radius: var(--dls-size-16);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--dls-size-16);
    gap: var(--dls-size-8);
  }

  .close-button {
    font-size: 24px;
    margin-left: auto;
  }

  .expanded-review-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: var(--dls-size-16);
  }

  .rating {
    display: flex;
  }

  .expanded-review-body,
  .expanded-review-date {
    color: var(--dls-secondary-text-colors);
  }

  .publish-details {
    display: flex;
    gap: var(--dls-size-8);
    align-items: start;
    padding: var(--dls-size-4) 0;
  }

  .profile-icon {
    width: var(--dls-size-32);
    height: var(--dls-size-32);
    background-color: rgba(224, 224, 224, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      stroke: var(--dls-primary-text-colors);
    }
  }
  @media screen and (min-width: 786px) {
    .modal-content {
      max-width: 500px;
    }
  }
`
