import styled from 'styled-components'

export const MultiProductSelectorContainer = styled.div`
  color: var(--dls-brand-primary-color-500);
  margin: auto;
  .individual-container {
    padding-bottom: var(--dls-size-16);
  }
  .product-card-container {
    display: flex;
    align-items: center;
  }
  .product-details-container {
    display: flex;
    align-items: start;
  }
  .media-container {
    width: 75px;
    height: auto;
    object-fit: contain;
  }
  .product-data-container {
    margin: var(--dls-size-8);
  }
  .pricing {
    display: flex;
  }
  .price-ctn {
    display: flex;
    align-items: center;
  }
  .price {
    color: var(--dls-error-color-500);
  }

  .discounted-price {
    text-decoration: line-through;
    margin-left: var(--dls-size-4);
  }

  .plus-icon {
    display: flex;
    justify-content: center;
    padding: var(--dls-size-8) 0;
  }

  .totals-text {
    display: flex;
    align-items: center;
    justify-content: center;
    .price {
      margin-left: var(--dls-size-4);
      color: var(--dls-black-color);
    }
    .discounted-price {
      color: var(--dls-black-color);
    }
  }
`
