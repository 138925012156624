import Accordion from './Accordion/Accordion'
import CallToActions from './CallToActions/CallToActions'
import MediaSlider from './MediaSlider/MediaSlider'
import MediaCarousel from './MediaCarousel/MediaCarousel'
import MediaBanner from './MediaBanner/MediaBanner'
import ProductSwitcher from './ProductSwitcher/ProductSwitcher'
import ProductTestimonials from './ProductTestimonials/ProductTestimonials'
import InfoCards from './InfoCards/InfoCards'
import MediaLabeledSlider from './MediaLabeledSlider/MediaLabeledSlider'
import MediaWithHeaderSlider from './MediaWithHeaderSlider/MediaWithHeaderSlider'
import MediaWithFooterSlider from './MediaWithFooterSlider/MediaWithFooterSlider'
import MediaWithProductOverlay from './MediaWithProductOverlay/MediaWithProductOverlay'
import ReelsSlider from './ReelsSlider/ReelsSlider'
import SocialReviewList from './SocialReviewList/SocialReviewList'
import BrickSection from './BrickSection/BrickSection'
import MarqueeWidget from './MarqueeWidget/Marquee'
import MediaBackgroundWithCta from './MediaBackgroundWithCta/MediaBackgroundWithCta'
import MediaWithInfoCards from './MediaWithInfoCards/MediaWithInfoCards'
import CatalogCards from './CatalogCards/CatalogCards'
import ReviewImageSlider from './ReviewImageSlider/ReviewImageSlider'
import CategoryCardGrid from './CategoryCardGrid/CategoryCardGrid'
import ProductSummary from './ProductSummary/ProductSummary'
import MediaWithProgressSlider from './MediaWithProgressSlider/MediaWithProgressSlider'
import MediaWithPillsSlider from './MediaWithPillsSlider/MediaWithPillsSlider'
import RatingsAndReviews from './RatingsAndReviews/RatingsAndReviews'
import ProductCardGridContainer from './ProductCardGrid/ProductCardGridContainer'
import ProductCardSliderContainer from './ProductCardSlider/ProductCardSliderContainer'
import CustomWidget from './CustomWidget/CustomWidget'
import InformationGridStrip from './InformationGridStrip/InformationGridStrip'
import ComparisonTable from './ComparisonTable/ComparisonTable'
import CalloutWithImage from './CalloutWithImage/CalloutWithImage'
import MediaGrid from './MediaGrid/MediaGrid'
import SearchInputWithRecommendationWrapper from '../widgets/SearchInputWithRecommendation/SearchInputWithRecommendationWrapper'
import StickyCartSummary from '../widgets/StickyCartSummary'
import HorizontalProductCardGridContainer from './HorizontalProductCardGrid/HorizontalProductCardGridContainer'
import OfferCouponCard from './OfferCouponCard/OfferCouponCard'
import ExpandedMedia from './ExpandedMedia/ExpandedMedia'
import MultiProductSelector from './MultiProductSelector/MultiProductSelector'
import RecentlyViewedCatalogCards from './RecentlyViewedCatalogCards/RecentlyViewedCatalogCards'
import MediaTextGridTabs from './MediaTextGridTabs/MediaTextGridTabs'
import MediaWithList from './MediaWithList/MediaWithList'
import MediaWithReadMore from './MediaWithReadMore/MediaWithReadMore'
import MediaTextBlock from './MediaTextBlock/MediaTextBlock'
import MediaWithInfoPanel from './MediaWithInfoPanel/MediaWithInfoPanel'
import ImageCtaGridView from './ImageCtaGridView/ImageCtaGridView'

export const BASE_WIDGET_MAP_TYPES = {
  ACCORDION: 'ACCORDION',
  BANNER: 'BANNER',
  BRICK_SECTION: 'BRICK_SECTION',
  CALLOUT_WITH_IMAGE: 'CALLOUT_WITH_IMAGE',
  CALL_TO_ACTION: 'CALL_TO_ACTION',
  CATALOG_CARDS: 'CATALOG_CARDS',
  CATEGORY_CARD_GRID: 'CATEGORY_CARD_GRID',
  COMPARISON_TABLE: 'COMPARISON_TABLE',
  CUSTOM_COMPONENT: 'CUSTOM_COMPONENT',
  HORIZONTAL_PRODUCT_CARD_GRID: 'HORIZONTAL_PRODUCT_CARD_GRID',
  INFORMATION_GRID_STRIP: 'INFORMATION_GRID_STRIP',
  INFO_CARDS: 'INFO_CARDS',
  MARQUEE: 'MARQUEE',
  MEDIA_BACKGROUND_WITH_CTA: 'MEDIA_BACKGROUND_WITH_CTA',
  MEDIA_CAROUSEL: 'MEDIA_CAROUSEL',
  MEDIA_GRID: 'MEDIA_GRID',
  MEDIA_LABELED_SLIDER: 'MEDIA_LABELED_SLIDER',
  MEDIA_SLIDER: 'MEDIA_SLIDER',
  MEDIA_WITH_FOOTER_SLIDER: 'MEDIA_WITH_FOOTER_SLIDER',
  MEDIA_WITH_HEADER_SLIDER: 'MEDIA_WITH_HEADER_SLIDER',
  MEDIA_WITH_INFO_CARDS: 'MEDIA_WITH_INFO_CARDS',
  MEDIA_WITH_PRODUCT_OVERLAY: 'MEDIA_WITH_PRODUCT_OVERLAY',
  MULTI_PRODUCT_SELECTOR: 'MULTI_PRODUCT_SELECTOR',
  PRODUCT_CARD_GRID: 'PRODUCT_CARD_GRID',
  PRODUCT_CARD_SLIDER: 'PRODUCT_CARD_SLIDER',
  PRODUCT_LISTING: 'PRODUCT_LISTING',
  PRODUCT_SWITCHER: 'PRODUCT_SWITCHER',
  PRODUCT_TESTIMONIALS: 'PRODUCT_TESTIMONIALS',
  REELS_SLIDER: 'REELS_SLIDER',
  REVIEW_IMAGE_SLIDER: 'REVIEW_IMAGE_SLIDER',
  SEARCH_INPUT_WITH_RECOMMENDATION: 'SEARCH_INPUT_WITH_RECOMMENDATION',
  SOCIAL_REVIEW_LIST: 'SOCIAL_REVIEW_LIST',
  STICKY_CART_SUMMARY: 'STICKY_CART_SUMMARY',
  PRODUCT_SUMMARY: 'PRODUCT_SUMMARY',
  MEDIA_WITH_PROGRESS_SLIDER: 'MEDIA_WITH_PROGRESS_SLIDER',
  MEDIA_WITH_PILLS_SLIDER: 'MEDIA_WITH_PILLS_SLIDER',
  RATINGS_AND_REVIEWS: 'RATINGS_AND_REVIEWS',
  OFFER_COUPON_CARD: 'OFFER_COUPON_CARD',
  EXPANDED_MEDIA: 'EXPANDED_MEDIA',
  RECENTLY_VIEWED_CATALOG_CARDS: 'RECENTLY_VIEWED_CATALOG_CARDS',
  MEDIA_TEXT_GRID_TABS: 'MEDIA_TEXT_GRID_TABS',
  MEDIA_WITH_LIST: 'MEDIA_WITH_LIST',
  MEDIA_WITH_READ_MORE: 'MEDIA_WITH_READ_MORE',
  MEDIA_TEXT_BLOCK: 'MEDIA_TEXT_BLOCK',
  MEDIA_WITH_INFO_PANEL: 'MEDIA_WITH_INFO_PANEL',
  IMAGE_CTA_GRID: 'IMAGE_CTA_GRID',
}

export type WidgetType =
  (typeof BASE_WIDGET_MAP_TYPES)[keyof typeof BASE_WIDGET_MAP_TYPES]

export const BASE_WIDGET_MAP: Record<WidgetType, React.ComponentType<any>> = {
  [BASE_WIDGET_MAP_TYPES.ACCORDION]: Accordion,
  [BASE_WIDGET_MAP_TYPES.BANNER]: MediaBanner,
  [BASE_WIDGET_MAP_TYPES.BRICK_SECTION]: BrickSection,
  [BASE_WIDGET_MAP_TYPES.CALLOUT_WITH_IMAGE]: CalloutWithImage,
  [BASE_WIDGET_MAP_TYPES.CALL_TO_ACTION]: CallToActions,
  [BASE_WIDGET_MAP_TYPES.CATALOG_CARDS]: CatalogCards,
  [BASE_WIDGET_MAP_TYPES.COMPARISON_TABLE]: ComparisonTable,
  [BASE_WIDGET_MAP_TYPES.CUSTOM_COMPONENT]: CustomWidget,
  [BASE_WIDGET_MAP_TYPES.HORIZONTAL_PRODUCT_CARD_GRID]:
    HorizontalProductCardGridContainer,
  [BASE_WIDGET_MAP_TYPES.INFORMATION_GRID_STRIP]: InformationGridStrip,
  [BASE_WIDGET_MAP_TYPES.INFO_CARDS]: InfoCards,
  [BASE_WIDGET_MAP_TYPES.MARQUEE]: MarqueeWidget,
  [BASE_WIDGET_MAP_TYPES.MEDIA_BACKGROUND_WITH_CTA]: MediaBackgroundWithCta,
  [BASE_WIDGET_MAP_TYPES.MEDIA_CAROUSEL]: MediaCarousel,
  [BASE_WIDGET_MAP_TYPES.MEDIA_GRID]: MediaGrid,
  [BASE_WIDGET_MAP_TYPES.MEDIA_LABELED_SLIDER]: MediaLabeledSlider,
  [BASE_WIDGET_MAP_TYPES.MEDIA_SLIDER]: MediaSlider,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_FOOTER_SLIDER]: MediaWithFooterSlider,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_HEADER_SLIDER]: MediaWithHeaderSlider,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_INFO_CARDS]: MediaWithInfoCards,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_PRODUCT_OVERLAY]: MediaWithProductOverlay,
  [BASE_WIDGET_MAP_TYPES.MULTI_PRODUCT_SELECTOR]: MultiProductSelector,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_CARD_GRID]: ProductCardGridContainer,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_CARD_SLIDER]: ProductCardSliderContainer,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_SWITCHER]: ProductSwitcher,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_TESTIMONIALS]: ProductTestimonials,
  [BASE_WIDGET_MAP_TYPES.REELS_SLIDER]: ReelsSlider,
  [BASE_WIDGET_MAP_TYPES.REVIEW_IMAGE_SLIDER]: ReviewImageSlider,
  [BASE_WIDGET_MAP_TYPES.SEARCH_INPUT_WITH_RECOMMENDATION]:
    SearchInputWithRecommendationWrapper,
  [BASE_WIDGET_MAP_TYPES.SOCIAL_REVIEW_LIST]: SocialReviewList,
  [BASE_WIDGET_MAP_TYPES.STICKY_CART_SUMMARY]: StickyCartSummary,
  [BASE_WIDGET_MAP_TYPES.CATEGORY_CARD_GRID]: CategoryCardGrid,
  [BASE_WIDGET_MAP_TYPES.PRODUCT_SUMMARY]: ProductSummary,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_PROGRESS_SLIDER]: MediaWithProgressSlider,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_PILLS_SLIDER]: MediaWithPillsSlider,
  [BASE_WIDGET_MAP_TYPES.RATINGS_AND_REVIEWS]: RatingsAndReviews,
  [BASE_WIDGET_MAP_TYPES.OFFER_COUPON_CARD]: OfferCouponCard,
  [BASE_WIDGET_MAP_TYPES.EXPANDED_MEDIA]: ExpandedMedia,
  [BASE_WIDGET_MAP_TYPES.RECENTLY_VIEWED_CATALOG_CARDS]:
    RecentlyViewedCatalogCards,
  [BASE_WIDGET_MAP_TYPES.MEDIA_TEXT_GRID_TABS]: MediaTextGridTabs,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_LIST]: MediaWithList,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_READ_MORE]: MediaWithReadMore,
  [BASE_WIDGET_MAP_TYPES.MEDIA_TEXT_BLOCK]: MediaTextBlock,
  [BASE_WIDGET_MAP_TYPES.MEDIA_WITH_INFO_PANEL]: MediaWithInfoPanel,
  [BASE_WIDGET_MAP_TYPES.IMAGE_CTA_GRID]: ImageCtaGridView,
}
