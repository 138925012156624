import React, {useCallback, useState} from 'react'

import {
  ThinMinusIcon,
  ThinPlusIcon,
  Typography,
  TypographyVariants,
} from '@web-components'
import {
  QuantityIndicator,
  StickyBuyNow,
  StyledWrapper,
  AddToCartButton,
} from './BuyOptionsWithQty.styles'
import {useStickyBuyOptions} from './useStickyBuyOptions'

import {BaseWidget, ToastType} from '@mosaic-wellness/fe-types'
import {useGenericActions} from 'src/mono/web-core/hooks'
import {Toast} from 'src/mono/web-core/Components'
import {BaseWidgetComponent} from '../../BaseWidget'

function BuyOptionsWithQty(props: BaseWidget) {
  const {id, layout, widgetData, type} = props

  const {productInfo} = widgetData || {}

  const {
    sku,
    name = '',
    actualPriceLabel = '',
    discountedPriceLabel = '',
  } = productInfo || {}

  const {showStickyFooter, buttonGroupRef} = useStickyBuyOptions()
  const {handleGenericActions} = useGenericActions()
  const [quantity, setQuantity] = useState(1)

  const handleIncreaseQuantity = useCallback(() => {
    setQuantity((qty) => {
      if (qty >= 5) {
        Toast(ToastType.ERROR, 'Maximum quantity limit reached')

        return qty
      }
      return qty + 1
    })
  }, [])

  const handleDecreaseQuantity = useCallback(() => {
    setQuantity((qty) => (qty - 1 >= 1 ? qty - 1 : 1))
  }, [])

  const handleAddToCart = useCallback(() => {
    const action = {
      actionName: 'ADD_TO_CART',
      params: {
        ...productInfo,
        sku,
        quantity,
        source: id,
      },
    }
    handleGenericActions([action])
  }, [handleGenericActions, id, productInfo, quantity, sku])

  return (
    <>
      <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
        <StyledWrapper ref={buttonGroupRef}>
          <div className="cta-wrapper">
            <QuantityIndicator>
              <div className="decrease" onClick={handleDecreaseQuantity}>
                <ThinMinusIcon />
              </div>

              <div className="quantity">{quantity}</div>
              <div className="increase" onClick={handleIncreaseQuantity}>
                <ThinPlusIcon />
              </div>
            </QuantityIndicator>
            <AddToCartButton onClick={handleAddToCart}>
              <Typography
                variant={TypographyVariants.CTA_LABEL_SMALL}
                customClassName="atc-btn"
              >
                Add to Cart
              </Typography>
            </AddToCartButton>
          </div>
        </StyledWrapper>
        {showStickyFooter && (
          <StickyBuyNow>
            <div className="container">
              <div className="left">
                <div className="info">
                  <Typography variant={TypographyVariants.SUBHEADING_REGULAR}>
                    {name}
                  </Typography>
                </div>
                <div className="price-container">
                  {discountedPriceLabel ? (
                    <>
                      <Typography
                        variant={TypographyVariants.BODY_BASE_REGULAR}
                        customClassName="discounted-price"
                      >
                        {discountedPriceLabel}
                      </Typography>
                      <Typography
                        variant={TypographyVariants.BODY_BASE_REGULAR}
                        customClassName="original-price"
                      >
                        {actualPriceLabel}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                      {actualPriceLabel}
                    </Typography>
                  )}
                </div>
              </div>
              <div className="right">
                <StyledWrapper data-sticky={showStickyFooter}>
                  <div className="cta-wrapper">
                    <QuantityIndicator>
                      <div
                        className="decrease"
                        onClick={handleDecreaseQuantity}
                      >
                        <ThinMinusIcon />
                      </div>

                      <div className="quantity">{quantity}</div>
                      <div
                        className="increase"
                        onClick={handleIncreaseQuantity}
                      >
                        <ThinPlusIcon />
                      </div>
                    </QuantityIndicator>
                    <AddToCartButton onClick={handleAddToCart}>
                      <Typography
                        variant={TypographyVariants.CTA_LABEL_SMALL}
                        customClassName="atc-btn"
                      >
                        ADD TO CART
                      </Typography>
                    </AddToCartButton>
                  </div>
                </StyledWrapper>
              </div>
            </div>
          </StickyBuyNow>
        )}
      </BaseWidgetComponent>
    </>
  )
}

export default BuyOptionsWithQty
