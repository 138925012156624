import React from 'react'
import {MediaWithListWrapper} from './MediaWithList.styles'
import {
  BaseWidget,
  BaseWidgetProps,
  GenericAction,
} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {
  Media,
  OptimizedImage,
  RightTiltedArrow,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useGenericActions} from '../../hooks'
import {MediaWithItemsCardData} from './MediaWithList.interface'

const MediaWithList = (props: BaseWidgetProps<MediaWithItemsCardData>) => {
  const {widgetData, layout, id, header, type} = props
  const {media, aspectRatio = 1, items = [], tickIcon = ''} = widgetData || {}
  const {handleGenericActions} = useGenericActions()

  const handleElementClick = (actions: GenericAction[]) => {
    handleGenericActions(actions)
  }

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithListWrapper>
        <div className="media-content">
          <Media
            media={media}
            aspectRatio={aspectRatio}
            customClassName="media"
          />
        </div>
        <div className="list-content">
          {items.map((item, index) => (
            <div
              className="list-element"
              onClick={() => handleElementClick(item.cta)}
              key={`element-${index}`}
            >
              <div className="left-content">
                <OptimizedImage
                  source={tickIcon}
                  mobileViewWidth="TINY"
                  desktopViewWidth="TINY"
                  aspectHeight={24}
                  aspectWidth={24}
                />

                <div className="list-label">
                  <Typography variant={TypographyVariants.BODY_BASE_LIGHT}>
                    {item.label}
                  </Typography>
                </div>
              </div>
              <div className="right-content btn-icon-wrapper">
                <RightTiltedArrow color={'var(--dls-black-color)'} size="20" />
                <div className="arrow-icon-bottom">
                  <RightTiltedArrow
                    color={'var(--dls-black-color)'}
                    size="20"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </MediaWithListWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithList
