import styled from 'styled-components'

export const DesktopCategoryPaletteWrapper = styled.div<{noOfColumns: number}>`
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--dls-size-16);

  @media screen and (min-width: 786px) {
    grid-template-columns: ${({noOfColumns}) =>
      `repeat(${noOfColumns || 3}, 1fr)`};
  }
`
