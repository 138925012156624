import {useCallback} from 'react'
import {ProductSwitchContainer} from './ProductSwitcher.styles'
import {IProductSwitchProps} from './ProductSwitcher.interface'

import {BaseWidgetComponent} from '../BaseWidget'
import {OptimizedImage, Typography, TypographyVariants} from '@web-components'
import {useGenericActions} from '../../hooks'
import isEmpty from 'lodash/isEmpty'

function ProductSwitcher(props: IProductSwitchProps) {
  const {id, type, widgetData, layout, header} = props
  const {rows = [], urlKey} = widgetData || {}

  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (item: any) => {
      if (item.isSelected) {
        return
      }
      const {actions, label, slug = '#', params} = item || {}

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'Variant Clicked',
          eventData: {
            label,
            source: id,
            'Base Product Id': urlKey,
            'Variant Product Id': slug,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id, urlKey]
  )

  if (isEmpty(rows)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <ProductSwitchContainer>
        {rows.map((row, index) => {
          const {options = [], title = '', type} = row || {}
          return (
            <div key={index} className="row-container">
              <Typography
                variant={TypographyVariants.SUBHEADING_BOLD}
                customClassName="title"
              >
                {title}
              </Typography>
              <div className="cta-grid">
                {options.map((option) => {
                  const {
                    label,
                    isSelected,
                    imageUrl,
                    description,
                    slug = '#',
                  } = option || {}
                  switch (type) {
                    case 'IMAGE':
                      return (
                        <a
                          className="option-image-item"
                          key={label}
                          data-isselected={isSelected}
                          onClick={(e) => {
                            e.preventDefault()
                            handleClick(option)
                          }}
                          href={slug}
                        >
                          <div className="image-container">
                            <OptimizedImage
                              source={imageUrl}
                              aspectHeight="36"
                              aspectWidth="36"
                              customClassName="cta-image"
                            />
                          </div>
                          <Typography
                            variant={
                              isSelected
                                ? TypographyVariants.TAG_BOLD
                                : TypographyVariants.TAG_REGULAR
                            }
                            customClassName="cta-label"
                          >
                            <span dangerouslySetInnerHTML={{__html: label}} />
                          </Typography>
                        </a>
                      )

                    case 'IMAGE_WITH_TEXT':
                      return (
                        <a
                          className="option-image-with-text-item"
                          key={label}
                          data-isselected={isSelected}
                          onClick={(e) => {
                            e.preventDefault()
                            handleClick(option)
                          }}
                        >
                          <div className="image-container">
                            <OptimizedImage
                              source={imageUrl}
                              aspectHeight="36"
                              aspectWidth="36"
                              customClassName="cta-image"
                            />
                          </div>
                          <div className="text-container">
                            <Typography
                              variant={
                                isSelected
                                  ? TypographyVariants.TAG_BOLD
                                  : TypographyVariants.TAG_REGULAR
                              }
                              customClassName="cta-label"
                            >
                              <span dangerouslySetInnerHTML={{__html: label}} />
                            </Typography>
                            <Typography
                              variant={TypographyVariants.TAG_REGULAR}
                            >
                              {description}
                            </Typography>
                          </div>
                        </a>
                      )

                    case 'IMAGE_WITH_FOOTER':
                      return (
                        <a
                          className="option-image-with-title"
                          key={label}
                          data-isselected={isSelected}
                          onClick={(e) => {
                            e.preventDefault()
                            handleClick(option)
                          }}
                        >
                          <div className="image-container">
                            <OptimizedImage
                              source={imageUrl}
                              aspectHeight="36"
                              aspectWidth="36"
                              customClassName="cta-image"
                              mobileViewWidth="SMALL"
                              desktopViewWidth="SMALL"
                            />
                          </div>
                          <div className="text-container">
                            <Typography
                              variant={TypographyVariants.X_SMALL}
                              customClassName="cta-label"
                            >
                              <span dangerouslySetInnerHTML={{__html: label}} />
                            </Typography>
                          </div>
                        </a>
                      )

                    default:
                      return (
                        <a
                          className="option-text-item"
                          key={label}
                          data-isselected={isSelected}
                          onClick={(e) => {
                            e.preventDefault()
                            handleClick(option)
                          }}
                        >
                          <Typography
                            variant={
                              isSelected
                                ? TypographyVariants.TAG_BOLD
                                : TypographyVariants.TAG_REGULAR
                            }
                            customClassName="cta-label"
                          >
                            <span dangerouslySetInnerHTML={{__html: label}} />
                          </Typography>
                        </a>
                      )
                  }
                })}
              </div>
            </div>
          )
        })}
      </ProductSwitchContainer>
    </BaseWidgetComponent>
  )
}

export default ProductSwitcher
