import styled from 'styled-components'

export const CouponContainer = styled.div`
  background: var(--dls-brand-primary-color-300);
  border: var(--dls-brand-primary-color-500);
  padding: var(--dls-size-8);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: var(--dls-size-8);
  color: var(--dls-white-color);
  width: 100%;
  margin: 0 auto;
  gap: var(--dls-size-8);
`

export const MainOffer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const CopyButton = styled.button`
  display: flex;
  align-items: center;
  gap: var(--dls-size-8);
  background-color: black;
  color: var(--dls-white-color);
  padding: var(--dls-size-8) 12px;
  border-radius: var(--dls-size-8);
  border: none;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  background: var(--dls-brand-primary-color-300);
  border: none;
  color: var(--dls-white-color);
`
