import React from 'react'
import {MediaTextBlockCardWrapper} from './MediaTextBlockCard.styles'
import {
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {ICardData} from './MediaTextBlockCard.interface'

const MediaTextBlockCard = (props: ICardData) => {
  const {cardData} = props
  const {media, textContent} = cardData

  return (
    <MediaTextBlockCardWrapper contentPosition={textContent.contentPosition}>
      <div className="media-content">
        <Media
          media={media}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          customClassName="media"
        />
      </div>

      <div className="text-content">
        <Typography
          variant={TypographyVariants.HEADING_MEDIUM_REGULAR}
          customClassName="heading"
        >
          {textContent.heading}
        </Typography>
        <Typography
          variant={TypographyVariants.BODY_BASE_REGULAR}
          customClassName="info"
        >
          {textContent.info}
        </Typography>
      </div>
    </MediaTextBlockCardWrapper>
  )
}

export default MediaTextBlockCard
