import React from 'react'
import {MediaWithPillsSliderWrapper} from './MediaWithPillsSlider.styles'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {Carousel} from '@web-components'

import {IMediaWithPillsSliderProps} from './MediaWithPillsSlider.interface'
import MediaWithPills from './MediaWithPills/MediaWithPills'

const MediaWithPillsSlider = (
  props: BaseWidgetProps<IMediaWithPillsSliderProps>
) => {
  const {widgetData, type, layout, id, header} = props
  const {items = [], carouselConfig} = widgetData || {}
  const {
    showDots = false,
    showArrows = true,
    slidesToShow = 1,
    slidesToShowDesktop = 3,
    infinite = true,
    slidesToScroll = 1,
    arrowPosition = 'inside',
    aspectRatio = 1,
  } = carouselConfig || {}

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithPillsSliderWrapper>
        <div className="slider">
          <Carousel
            showDots={showDots}
            showArrows={showArrows}
            slidesToShow={slidesToShow}
            slidesToShowDesktop={slidesToShowDesktop}
            infinite={infinite}
            slidesToScroll={slidesToScroll}
            arrowPosition={arrowPosition}
          >
            {items.map((item: any, index: number) => {
              return (
                <MediaWithPills
                  key={index}
                  data={item}
                  aspectRatio={aspectRatio}
                />
              )
            })}
          </Carousel>
        </div>
      </MediaWithPillsSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithPillsSlider
