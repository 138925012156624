import {useCallback, useMemo, useState} from 'react'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {
  CrossIcon,
  Media,
  Modal,
  Slider,
  Typography,
  TypographyVariants,
} from '@web-components'
import {IItem, IReelSlider} from './ReelsSlider.interface'
import {ReelsSliderContainer} from './ReelsSlider.styles'
import {useIntersection} from '../../hooks/useIntersection'

const ReelsSlider = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<IReelSlider>) => {
  const {items = [], variant = 'default', sliderConfig} = widgetData

  const {
    aspectRatio = 1,
    slidesToShow,
    showPeek,
    peekVariant,
    slidesToShowDesktop,
    showArrows,
    arrowPosition = 'inside',
  } = sliderConfig || {}

  const {isIntersecting, nodeRef} = useIntersection({
    rootMargin: '20px',
    threshold: 0.1,
    keepObserving: true,
    initialValue: true,
  })
  const [isOpen, setIsOpen] = useState(false)
  const [modalData, setModalData] = useState<null | IItem['modalMedia']>(null)
  const [currentSlide, setCurrentSlide] = useState(0)

  const variantClassName = useMemo(() => {
    switch (variant) {
      case '3D':
        return 'three-dimensional-slider-card'
      case 'circular':
        return 'circular-slider-card'
      default:
        return 'slider-card'
    }
  }, [variant])

  const onThumbnailClick = useCallback((modalMedia: IItem['modalMedia']) => {
    setModalData(modalMedia)
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setModalData(null)
    setIsOpen(false)
  }, [])

  if (!items || isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ReelsSliderContainer ref={nodeRef}>
        <Slider
          aspectRatio={aspectRatio}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          showPeek={showPeek}
          peekVariant={peekVariant}
          showArrows={showArrows}
          itemCount={items.length}
          onSlideChange={(index) => setCurrentSlide(index)}
          arrowPosition={arrowPosition}
          isMobileView={layout?.isSupportedOnlyForMobile}
        >
          {() =>
            items.map((video, index) => {
              const {media, modalMedia} = video || {}
              return (
                <div
                  className={variantClassName}
                  key={index}
                  onClick={() =>
                    onThumbnailClick(modalMedia || {...media, muted: false})
                  }
                  data-is-active={currentSlide + 1 === index}
                >
                  {isIntersecting && (
                    <Media
                      media={media}
                      desktopViewWidth={'THIRD'}
                      mobileViewWidth={'THIRD'}
                      aspectRatio={aspectRatio}
                      customClassName="media"
                    />
                  )}
                </div>
              )
            })
          }
        </Slider>

        {modalData && (
          <Modal
            showModal={isOpen}
            onClose={handleClose}
            additionalModalClass="reel-modal"
            additionalContentClass="reel-modal-content"
            hideCloseButton={true}
          >
            <div className="close-icon" onClick={handleClose}>
              <Typography variant={TypographyVariants.HEADING_SMALL_REGULAR}>
                x
              </Typography>
            </div>
            <Media
              media={{...modalData, muted: false}}
              aspectRatio={'none'}
              customClassName="modal-media"
            />
          </Modal>
        )}
      </ReelsSliderContainer>
    </BaseWidgetComponent>
  )
}

export default ReelsSlider
