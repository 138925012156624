import React from 'react'
import {MediaWithReadMoreCardWrapper} from './MediaWithReadMoreCard.styles'
import {Media, Typography, TypographyVariants} from '@web-components'
import {useGenericActions} from '../../../hooks/useGenericActions'
import {GenericAction} from '@mosaic-wellness/fe-types'
import {IMediaWithReadMoreCard} from './MediaWithReadMoreCard.interface'

const MediaWithReadMoreCard = (props: IMediaWithReadMoreCard) => {
  const {cardData} = props
  const {media, content, slug = '#'} = cardData || {}
  const {title = '', info = '', cta = {label: '', actions: []}} = content || {}

  const {handleGenericActions} = useGenericActions()

  const handleCtaClick = (actions: GenericAction[]) => {
    handleGenericActions(actions)
  }

  return (
    <MediaWithReadMoreCardWrapper>
      <div className="media-container">
        <Media
          media={media}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          customClassName="media"
        />
      </div>
      <div className="content">
        <Typography variant={TypographyVariants.SUBHEADING_REGULAR}>
          {title}
        </Typography>
        <Typography
          variant={TypographyVariants.TAG_REGULAR}
          customClassName="info-text"
        >
          {info}
        </Typography>
        <a
          href={slug}
          className="card-cta"
          onClick={() => handleCtaClick(cta.actions)}
        >
          <Typography variant={TypographyVariants.TAG_REGULAR}>
            {cta.label}
          </Typography>
        </a>
      </div>
    </MediaWithReadMoreCardWrapper>
  )
}

export default MediaWithReadMoreCard
