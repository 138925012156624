import React from 'react'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {Marquee} from '@web-components'
import {IMarquee} from './Marquee.interface'

const MarqueeWidget = (props: BaseWidgetProps<IMarquee>) => {
  const {widgetData, layout, type, id} = props
  const {items} = widgetData || {}
  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <Marquee items={items} />
    </BaseWidgetComponent>
  )
}

export default MarqueeWidget
