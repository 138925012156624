import {MediaGridProps} from './MediaGrid.interface'
import {useGenericActions} from '../../hooks'
import {useCallback, useMemo} from 'react'
import {GenericAction} from '@mosaic-wellness/fe-types'
import {useDeviceTypeContext} from '@web-components'
import {interceptAndEnhanceAction} from '../../utils/interceptAndEnhanceAction'

export const useTiles = ({
  id,
  widgetData,
}: {
  id: string
  widgetData: MediaGridProps
}) => {
  const {isMobile} = useDeviceTypeContext()
  const {handleGenericActions} = useGenericActions()

  const {
    items = [],
    noOfColumnsForMobile = 3,
    noOfColumnsForDesktop = 3,
    aspectRatio = 1,
    scaleFullWidthDesktop = false,
  } = widgetData

  const noOfColumns = useMemo(
    () => (isMobile ? noOfColumnsForMobile : noOfColumnsForDesktop),
    [isMobile, noOfColumnsForDesktop, noOfColumnsForMobile]
  )

  const handleCtaClick = useCallback(
    (actions: GenericAction[], label?: string) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id]
  )

  return {
    noOfColumns,
    handleCtaClick,
    items,
    aspectRatio,
    scaleFullWidthDesktop,
  }
}
