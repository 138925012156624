import styled from 'styled-components'

export const ReelsSliderContainer = styled.div`
  width: 100%;

  .reel-modal {
    background-color: black;
  }

  .reel-modal-content {
    width: 100%;
    height: 100%;
    position: relative;

    .close-icon {
      position: absolute;
      top: 20px;
      right: 40px;
      color: var(--dls-white-color);
      z-index: 1;
    }
    .modal-media {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .media-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  .slider-card {
    .media {
      object-fit: cover;
      width: 100%;
      height: auto;
      border-radius: var(--dls-size-16);
    }
  }

  .three-dimensional-slider-card {
    padding: 30px var(--dls-size-8);
    .media {
      object-fit: cover;
      width: 100%;
      height: auto;
      border-radius: var(--dls-size-4);
    }

    &[data-is-active='true'] {
      transform: scale(1.2);
    }
  }

  .circular-slider-card {
    .media {
      border-radius: 50%;
      padding: 4px;
      object-fit: cover;
      border: 1px solid var(--dls-brand-primary-color-500);
    }
  }

  @media screen and (min-width: 786px) {
    .reel-modal-content {
      max-width: 500px;
      display: inline-block;
    }
    .three-dimensional-slider-card {
      padding: 30px var(--dls-size-8);
      .media {
        object-fit: cover;
        width: 100%;
        height: auto;
        border-radius: var(--dls-size-4);
      }

      &[data-is-active='true'] {
        transform: none;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`
