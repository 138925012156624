import styled from 'styled-components'

export const StyledWrapper = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  width: 100%;
  .preorder {
    display: flex;
    background-color: var(--brand-primary-white-color);
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-bottom: 12px;
    padding-top: 4px;
  }
  .preorder-label {
    font-family: var(--brand-font-family);
    font-weight: var(--brand-font-weight-bold);
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: var(--brand-primary-green-color);
  }
  .timer-img {
    margin-right: 8px;
    margin-left: 5px;
    width: 12px;
    height: 16px;
    margin-bottom: 5px;
  }
  .line {
    width: 2px;
    height: 18px;
    background: var(--dls-brand-primary-color-400);
    margin: 0 8px;
  }
  .preorder-subtitle {
    font-family: var(--brand-font-family);
    font-weight: var(--brand-font-weight-normal);
    font-size: 12px;
    line-height: 120%;
    padding: 2px 10px;
    text-align: center;
    color: var(--brand-primary-green-color);
    background: var(--brand-primary-green-bg2-color);
    border-radius: 13px;
  }

  .cta-wrapper {
    display: flex;
    align-items: center;

    gap: 15px;
    @media (min-width: 768px) {
      gap: var(--dls-size-24);
    }
  }

  @media (min-width: 768px) {
    z-index: unset;
    order: 5;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 40px 0 0;
    .preorder {
      background: transparent;
      height: auto;
      margin-bottom: 21px;
    }
    .preorder-label {
      font-size: 20px;
      line-height: 120%;
      margin-bottom: 0;
    }
    .timer-img {
      margin-right: 3px;
      width: 16px;
      height: 20px;
    }
    .line {
      width: 2px;
      height: 100%;
      margin: 0 10px;
      left: 1027px;
      top: 525px;
      background: var(var(--dls-brand-primary-color-400));
    }
    .preorder-subtitle {
      font-size: 14px;
      line-height: 120%;
      padding: 4px 10px;
      margin-bottom: 0;
    }
    &[data-sticky='true'] {
      margin: 0;
    }
  }
`

export const StyledButton = styled.button`
  flex-grow: 1;
  margin: 0;
  border: none;
  background: none;
  font-size: 16px;
  line-height: 18px;
  font-weight: var(--brand-font-weight-medium);
  padding: 16px 0;
  text-align: center;
  color: var(--brand-primary-white-color);

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 23px;
    font-weight: var(--brand-font-weight-bold);
    padding: 18px 0;
    border-radius: 20px;
  }
`
export const AddToCartCta = styled(StyledButton)`
  padding: 22px 0;
  position: relative;
  background-color: var(--brand-primary-black-color);
  overflow: hidden;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: transparent;
`
export const BuyNowCta = styled(StyledButton)`
  padding: 22px 0;
  position: relative;
  background-color: var(--dls-brand-primary-color-500);
  min-width: 50%;
  overflow: hidden;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 768px) {
    margin-left: 10px;
  }
`

export const ModalMount = styled.div`
  .buy-now-content-wrap {
    background-color: transparent;
    padding: 0px;
  }
  .buy-now-button-wrap {
  }
  @media (min-width: 768px) {
    .buy-now-button-wrap {
      top: -10px;
      right: -15px;
    }
  }
`
export const StyledBuyNowListing = styled.div`
  color: var(--brand-primary-white-color);
  background-color: var(--brand-primary-white-color);
  border-radius: 20px 20px 0px 0px;
  padding: 10px;

  @media (min-width: 768px) {
    border-radius: 20px;
    .category-Primary-btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

export const StyledTitleWrapper = styled.div`
  width: 100%;
  background-color: var(--brand-primary-white-color);
  color: var(--brand-primary-black-color);
  margin-bottom: 14px;
  padding: 10px 10px 0 10px;

  .title {
    font-weight: var(--brand-font-weight-bold);
    font-size: 20px;
    line-height: 1;
    margin-bottom: 8px;
  }

  .subtitle {
    font-weight: var(--brand-font-weight-400);
    font-size: 14px;
    line-height: 1;
  }

  @media (min-width: 768px) {
    background-color: transparent;
    color: var(--brand-primary-white-color);

    .title {
      font-size: 24px;
    }

    .subtitle {
      font-size: 18px;
    }
  }
`

export const StyledListWrapper = styled.div`
  border-radius: 20px 20px 0px 0px;
  color: var(--brand-primary-black-color);
  background-color: var(--brand-primary-white-color);
  margin-bottom: var(--dls-size-24);

  > * + * {
    margin-top: 4px;
  }

  @media (min-width: 768px) {
    border-radius: 20px;
    border-radius: 20px;
    margin-bottom: var(--dls-size-16);
  }
`

export const StyledItem = styled.div`
  border-radius: 20px;
  width: 100%;
  gap: 12px;
  height: 68px;
  display: flex;
  align-items: flex-start;
  padding: 12px;
  cursor: pointer;
  transition: all 0.4s;

  &[data-selected='true'],
  :hover {
    background-color: var(--category-primary-bg1-color);
  }

  .selection-indicator {
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }

  .price-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;
  }

  .product-info {
    flex-grow: 1;
    margin-left: 10px;
  }

  .product-name {
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 2px;
  }

  .quantity {
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
  }

  .product-price {
    display: flex;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    span {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
    }
  }

  .discount {
    padding: 4px 8px;
    font-weight: var(--brand-font-weight-bold);
    font-size: 12px;
    line-height: 14px;
    background: #ffc820;
    border-radius: 14px;
  }

  @media (min-width: 768px) {
    height: 72px;
    padding: 12px 22px;

    .product-name {
      font-size: 24px;
    }

    .quantity {
      font-size: 18px;
    }

    .selection-indicator {
      width: 16px;
      height: 16px;
      margin-top: 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .product-price {
      font-weight: bold;
      font-size: 24px;
      line-height: 100%;
      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        display: flex;
      }
    }

    .discount {
      margin-top: 7px;
      padding: 4px 10px;
      font-weight: var(--brand-font-weight-bold);
      font-size: 14px;
      line-height: 100%;
      background: #ffc820;
      border-radius: 14px;
    }
  }
`

export const StyledProceedToCheckout = styled.button`
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  height: 60px;
  width: 100vw;
  padding: 14px 18px;
  background-color: var(--category-primary-color);
  color: var(--brand-primary-white-color);
  margin-left: -10px;
  margin-bottom: -10px;
  position: relative;
  overflow: hidden;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 768px) {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    width: 444px;
    padding: 12px 18px;
    border-radius: 12px;
    margin-left: 0;
    margin-bottom: 0;
  }
`

export const StickyBuyNow = styled.div`
  background-color: var(--dls-backgound-color-500);
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  animation: slide-in 250ms;
  box-shadow: 0 0 10px #0003;

  @keyframes slide-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
  .container {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    margin: 0 auto;
    justify-content: center;
    padding: 10px 0;
    gap: 20px;
    padding-top: 22px;

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 20px 0;
    }

    .left {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-right: 30px;
      .product-info {
        flex-grow: 1;
      }
    }

    .right {
      flex-grow: 1;
      width: 100%;
      @media (min-width: 768px) {
        max-width: 524px;
      }
    }

    .price-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--dls-size-16);
    }

    .original-price {
      text-decoration: line-through;
      color: var(--dls-brand-primary-color-500);
    }
  }

  .pricing {
    font-weight: var(--brand-font-weight-bold);
    font-size: 48px;
    line-height: 55px;
    padding-top: 7px;
    display: flex;
    align-items: flex-start;
    margin-left: auto;
  }

  .currency {
    font-weight: var(--brand-font-weight-bold);
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    margin-top: 5px;
  }

  .info {
    display: flex;

    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }

  .info::after {
    content: '•';
    margin: 0 10px;
    color: var(--dls-black-color);
    font-size: 14px;
  }

  .product-image {
    width: 68px;
    height: 68px;
    border-radius: 8px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .subtitle {
    width: 380px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    font-weight: var(--brand-font-weight-normal);
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.25px;
    color: #111d3c;
    margin-top: 3px;
    width: 380px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const QuantityIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--dls-size-24);
  border: 1px solid var(--dls-black-color);
  padding: 5px 13px;
  border-radius: var(--dls-size-8);
  @media (min-width: 768px) {
    border-radius: 12px;
    padding: 10px 13px;
  }
  .decrease {
    flex-shrink: 0;
    cursor: pointer;
  }

  .increase {
    flex-shrink: 0;
    cursor: pointer;
  }
`

export const AddToCartButton = styled.div`
  padding: 11px 15px;
  background-color: var(--dls-brand-primary-color-500);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dls-white-color);
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
  width: 100%;

  border-radius: var(--dls-size-8);
  @media (min-width: 768px) {
    border-radius: 12px;
    padding: 12px 15px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--dls-brand-primary-color-400);
    z-index: 0;
    transition: left 0.3s ease;
    clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
  }

  &:hover::before {
    left: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  &:hover {
    color: var(--dls-white-color);
  }

  .atc-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
  }
`
