import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {Carousel} from '@web-components'
import TestimonialProductCard from './TestimonialProductCard/TestimonialProductCard'
import {ProductTestimonialsWrapper} from './ProductTestimonials.styles'
import {useGenericActions} from '../../hooks'
import {
  TestimonialProduct,
  ProductTestimonialsProps,
} from './ProductTestimonials.interface'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'

const ProductTestimonials = ({
  widgetData,
  id,
  header,
  layout,
  type,
}: BaseWidgetProps<ProductTestimonialsProps>) => {
  const {products = [], carouselConfig} = widgetData || {}
  const {
    showArrows,
    showDots,
    autoSlide,
    autoSlideInterval,
    infinite,
    arrowPosition,
  } = carouselConfig || {}

  const {handleGenericActions} = useGenericActions()

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductTestimonialsWrapper>
        <Carousel
          showDots={showDots}
          showArrows={showArrows}
          autoSlide={autoSlide}
          autoSlideInterval={autoSlideInterval}
          infinite={infinite}
          arrowPosition={arrowPosition}
        >
          {products.map((product: TestimonialProduct, index: number) => {
            return (
              <TestimonialProductCard
                key={index}
                product={product}
                handleGenericActions={handleGenericActions}
              />
            )
          })}
        </Carousel>
      </ProductTestimonialsWrapper>
    </BaseWidgetComponent>
  )
}

export default ProductTestimonials
