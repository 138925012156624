import styled from 'styled-components'

export const MediaSliderContainer = styled.div`
  &[data-show-border='true'] {
    .media-component {
      border: 1px solid var(--dls-divider-light-color);
      border-radius: var(--dls-size-8);
    }
  }
`
