import React, {useCallback, useMemo, useState} from 'react'
import {MediaWithInfoPanelWrapper} from './MediaWithInfoPanel.styles'
import {BaseWidget, BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {
  ChevronDown,
  ChevronUp,
  OptimizedImage,
  Typography,
  TypographyVariants,
  useDeviceTypeContext,
} from '@web-components'
import AccordionItem from './AccordionItem/AccordionItem'
import {BaseWidgetComponent} from '../BaseWidget'
import {IMediaWithInfoProps} from './MediaWithInfoPanel.interface'

const MediaWithInfoPanel = (props: BaseWidgetProps<IMediaWithInfoProps>) => {
  const {widgetData, header, id, type, layout} = props
  const {items = []} = widgetData || {}
  const {isMobile = false} = useDeviceTypeContext()
  const [activeTab, setActiveTab] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const handleTabClick = useCallback((index: number) => {
    setActiveTab(index)
    setIsOpen(false)
  }, [])

  const handleTabSelect = useCallback(() => {
    setIsOpen((state) => !state)
  }, [])

  const activeTabItem = useMemo(() => {
    return items[activeTab] || []
  }, [activeTab, items])

  const {imageUrl, content} = activeTabItem

  const {heading, details, iconListContent, accordionContent} = content || {}

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithInfoPanelWrapper>
        {isMobile ? (
          <div className="mobile-tabs-container">
            <div className="selected-tab" onClick={handleTabSelect}>
              <Typography variant={TypographyVariants.SUBHEADING_LIGHT}>
                {items[activeTab].tabName}
              </Typography>
              {isOpen ? (
                <ChevronUp color="white" />
              ) : (
                <ChevronDown color="white" />
              )}
            </div>
            {isOpen && (
              <div className="drop-down">
                {items.map((item, index) => (
                  <div
                    key={`drop-down-tab-${index}`}
                    onClick={() => handleTabClick(index)}
                    className="dropdown-tab"
                  >
                    <Typography variant={TypographyVariants.SUBHEADING_LIGHT}>
                      {item.tabName}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="tabs">
            {items.map((item, index: number) => (
              <div
                className={`tab ${index === activeTab ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
                key={`tab-elements-${index}`}
              >
                <Typography
                  variant={TypographyVariants.SUBHEADING_REGULAR}
                  customClassName="tab-element"
                >
                  {item.tabName}
                </Typography>
              </div>
            ))}
          </div>
        )}

        {activeTabItem && (
          <div className="info-panel">
            <div className="block-icon-wrapper">
              <OptimizedImage source={imageUrl} customClassName="block-icon" />
            </div>
            <div className="heading-content">
              <Typography
                variant={TypographyVariants.SUBHEADING_REGULAR}
                customClassName="italic"
              >
                {heading.title}
              </Typography>
              <ul>
                <li>
                  <Typography
                    variant={
                      isMobile
                        ? TypographyVariants.BODY_BASE_REGULAR
                        : TypographyVariants.TAG_REGULAR
                    }
                  >
                    {heading.info}
                  </Typography>
                </li>
              </ul>
            </div>
            <div className="detail-block">
              <div className="details-wrapper">
                {details.map((item, index) => (
                  <div className="detail" key={index}>
                    <Typography
                      variant={
                        isMobile
                          ? TypographyVariants.BODY_BASE_REGULAR
                          : TypographyVariants.TAG_REGULAR
                      }
                    >
                      {item.label}
                    </Typography>

                    <Typography
                      variant={
                        isMobile
                          ? TypographyVariants.TAG_REGULAR
                          : TypographyVariants.X_SMALL
                      }
                    >
                      {item.info}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <div className="icon-list-section">
              <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                {iconListContent.title}
              </Typography>
              <div className="icon-list">
                {iconListContent.items.map((item, index) => (
                  <div className="list-element" key={index}>
                    <OptimizedImage
                      source={item.icon}
                      desktopViewWidth="TINY"
                      mobileViewWidth="TINY"
                    />
                    <Typography
                      variant={
                        isMobile
                          ? TypographyVariants.TAG_REGULAR
                          : TypographyVariants.X_SMALL
                      }
                    >
                      {item.label}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <div className="accordion-section">
              {accordionContent.map((item, index) => (
                <AccordionItem
                  data={item.data}
                  iconVariant={item.iconVariant}
                  titleFontWeight={item.titleFontWeight}
                  key={index}
                  initiallyOpen={false}
                />
              ))}
            </div>
          </div>
        )}
      </MediaWithInfoPanelWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithInfoPanel
