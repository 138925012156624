import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'

import {ProductsGridContainer} from './ProductCardGrid.styles'
import {useCallback, useMemo} from 'react'

import {NoProductsFound, ProductCard} from '@web-components'
import {Product} from 'src/mono/web-components/components/molecules/ProductCard/ProductCard.types'
import noop from 'lodash/noop'
import {ProductCardGridProps} from './ProductCardGrid.interface'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'

function ProductCardGrid(props: BaseWidgetProps<ProductCardGridProps>) {
  const {type, widgetData, header, layout, id} = props || {}
  const {
    products = [],
    showEmptyState = true,
    emptyState,
    enableQuantityIndicator = false,
    showMobileViewOnDesktop = false,
    shouldOpenMiniPdp = false,
    miniPdpCta = {},
    productImageAspectRatio = 1,
    isCartUpdating,
    itemBeingAddedToCart,
    cartItems,
    handleCardClick = noop,
    handleCtaClick = noop,
    handleUpdateCartItem = noop,
  } = widgetData || {}

  const showNoProductsEmptyState = useMemo(
    () => isEmpty(products) && showEmptyState,
    [products, showEmptyState]
  )

  const handleCardClickCallback = useCallback(
    (product: Product) => {
      handleCardClick(product, shouldOpenMiniPdp, miniPdpCta)
    },
    [handleCardClick, shouldOpenMiniPdp, miniPdpCta]
  )

  if (isEmpty(products) && !showEmptyState) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductsGridContainer showMobileViewOnDesktop={showMobileViewOnDesktop}>
        {!showNoProductsEmptyState &&
          products.map((product: any, index: number) => (
            <div
              className="product-card-container"
              key={product.urlKey + index}
            >
              <ProductCard
                product={product}
                productImageAspectRatio={productImageAspectRatio}
                handleCardClick={handleCardClickCallback}
                handleCtaClick={handleCtaClick}
                handleUpdateCartItem={handleUpdateCartItem}
                enableQuantityIndicator={enableQuantityIndicator}
                isCartUpdating={isCartUpdating}
                itemBeingAddedToCart={itemBeingAddedToCart}
                cartItems={cartItems}
              />
            </div>
          ))}
      </ProductsGridContainer>
      {showNoProductsEmptyState && <NoProductsFound emptyState={emptyState} />}
    </BaseWidgetComponent>
  )
}

export default ProductCardGrid
