import React, {useCallback, useMemo, useState} from 'react'
import MediaWithTextGrid from './MediaWithTextGrid/MediaWithTextGrid'
import {
  ChevronDown,
  ChevronUp,
  Typography,
  TypographyVariants,
  useDeviceTypeContext,
} from '@web-components'
import {MediaWithTextGridWrapper} from './MediaTextGridTabs.styles'
import {BaseWidget, BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {IMediaTextGridTabs} from './MediaTextGridTabs.interface'
import {BaseWidgetComponent} from '../BaseWidget'

const MediaTextGridTabs = (props: BaseWidgetProps<IMediaTextGridTabs>) => {
  const {widgetData, type, layout, id, header} = props
  const {items = []} = widgetData
  const {isMobile = false} = useDeviceTypeContext()
  const [tabIndex, setTabIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const handleTabClick = useCallback((index: number) => {
    setTabIndex(index)
    setIsOpen(false)
  }, [])

  const handleTabSelect = useCallback(() => {
    setIsOpen((state) => !state)
  }, [])

  const cardData = useMemo(() => {
    return items[tabIndex]
  }, [items, tabIndex])

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithTextGridWrapper>
        {isMobile ? (
          <div className="mobile-tabs-container">
            <div className="selected-tab" onClick={handleTabSelect}>
              <Typography variant={TypographyVariants.SUBHEADING_LIGHT}>
                {items[tabIndex].tabName}
              </Typography>
              {isOpen ? (
                <ChevronUp color="white" />
              ) : (
                <ChevronDown color="white" />
              )}
            </div>
            {isOpen && (
              <div className="drop-down">
                {items.map((item, index) => (
                  <div
                    key={`drop-down-tab-${index}`}
                    onClick={() => handleTabClick(index)}
                    className="dropdown-tab"
                  >
                    <Typography variant={TypographyVariants.SUBHEADING_LIGHT}>
                      {item.tabName}
                    </Typography>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="tabs-container">
            {items?.map((item, index: number) => (
              <div
                key={index}
                className={`tab ${index === tabIndex ? 'active' : ''}`}
                onClick={() => handleTabClick(index)}
              >
                <Typography variant={TypographyVariants.BODY_BASE_LIGHT}>
                  {item.tabName}
                </Typography>
              </div>
            ))}
          </div>
        )}

        <MediaWithTextGrid cardData={cardData} />
      </MediaWithTextGridWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaTextGridTabs
