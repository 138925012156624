import styled from 'styled-components'

export const ImageCtaGridViewWrapper = styled.div`
  display: flex;
  width: 90%;
  margin: auto;
  flex-direction: column;
  gap: 12px;

  @media (min-width: 768px) {
    width: 75%;
    flex-direction: row;
  }

  .left-section {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
  }
  .center-section {
    flex-grow: 1;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      width: 80%;
    }
  }

  .center-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .center-image {
    width: 100%;
    object-fit: cover;
    margin: auto;
    @media (min-width: 768px) {
      width: 94%;
    }
  }
  .right-section {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
  }

  .cta-background-image-container {
    padding: var(--dls-size-16) var(--dls-size-16) var(--dls-size-8)
      var(--dls-size-16);
    background-color: #ffebda;
    height: auto;
    width: 100%;
    border-radius: var(--dls-size-32);
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  .cta-background-image-container-right {
    padding: var(--dls-size-16) var(--dls-size-16) var(--dls-size-8)
      var(--dls-size-16);
    background-color: #13523b;
    height: auto;
    width: 100%;
    border-radius: var(--dls-size-32);
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }

  .bottom-content-right {
    color: var(--dls-white-color);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    padding-left: var(--dls-size-32);
    padding-bottom: var(--dls-size-24);
  }

  .cta-background-image {
    width: 100%;
    object-fit: cover;
  }
  .bottom-content {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 235, 218, 1) 100%
    );
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    padding-left: var(--dls-size-32);
    padding-bottom: var(--dls-size-24);
  }

  .arrow-container {
    background-color: var(--dls-white-color);
    width: max-content;
    padding: var(--dls-size-8);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 30px;
  }

  .card-cta {
    background-color: var(--dls-white-color);
    width: 100%;
    cursor: pointer;
  }
  .card-cta-image {
    width: 100%;
  }
`
