import React, {useCallback, useMemo, useState} from 'react'
import {AccordionItemContainer} from './AccordionItem.styles'
import {IAccordionItemProps} from './AccordionItem.interface'
import {
  ChevronDown,
  ChevronUp,
  Typography,
  TypographyVariants,
  OptimizedImage,
  ThinMinusIcon,
  ThinPlusIcon,
} from '@web-components'

function AccordionItem(props: IAccordionItemProps) {
  const {
    data,
    initiallyOpen = false,
    iconVariant,
    titleFontWeight,
  } = props || {}

  const {content = '', title = '', image = ''} = data || {}

  const [isOpen, setIsOpen] = useState(initiallyOpen)
  const [isAnimating, setIsAnimating] = useState(false)

  const handleAccordionClick = useCallback(() => {
    setIsAnimating(true)
    setTimeout(() => {
      setIsOpen((flag) => !flag)
      setIsAnimating(false)
    }, 300)
  }, [])

  const renderIconVariant = useCallback(
    (iconVariant: string) => {
      switch (iconVariant) {
        case 'plus-minus':
          return (
            <div className={`icon-container ${isAnimating ? 'animating' : ''}`}>
              {isOpen ? (
                <ThinMinusIcon size="36" fill="var(--dls-white-color)" />
              ) : (
                <ThinPlusIcon size="36" fill="var(--dls-white-color)" />
              )}
            </div>
          )
        case 'chevron':
          return isOpen ? <ChevronUp /> : <ChevronDown />
        default:
          return null
      }
    },
    [isAnimating, isOpen]
  )

  if (!title) {
    return null
  }

  return (
    <AccordionItemContainer
      className="accordion-item"
      data-isopen={isOpen}
      data-isanimating={isAnimating}
    >
      <div onClick={handleAccordionClick}>
        <div className="title-container">
          {image && (
            <div className="title-image-container">
              <OptimizedImage
                source={image}
                customClassName="title-image"
                mobileViewWidth={'TINY'}
                desktopViewWidth={'TINY'}
              />
            </div>
          )}

          <Typography
            variant={
              titleFontWeight === 'light'
                ? TypographyVariants.SUBHEADING_REGULAR
                : TypographyVariants.BODY_BASE_BOLD
            }
            customClassName="title-text"
          >
            {title}
          </Typography>
          <div className="chevron-icon">{renderIconVariant(iconVariant)}</div>
        </div>

        <div className="content-text">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {content}
          </Typography>
        </div>
      </div>
    </AccordionItemContainer>
  )
}

export default AccordionItem
