import {BaseWidgetComponent} from '../BaseWidget'
import {Button, Media, Typography, TypographyVariants} from '@web-components'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useGenericActions} from '../../hooks'
import {MultiProductSelectorContainer} from './MultiProductSelector.styles'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {MultiProductSelectorProps} from './MultiProductSelector.interface'

function MultiProductSelector(
  props: BaseWidgetProps<MultiProductSelectorProps>
) {
  const {id, type, widgetData, header, layout} = props
  const {
    products = [],
    source,
    cta,
    totalPriceText = 'Total Price',
  } = widgetData || {}

  const {
    variant = 'PRIMARY',
    label = 'Add To Cart',
    size = 'MEDIUM',
    fullWidth = true,
    ...rest
  } = cta || {}

  // Initialize selectedProducts with all product urlKeys
  const [selectedProducts, setSelectedProducts] = useState<string[]>(
    products.map((product) => product.urlKey)
  )

  const {handleGenericActions} = useGenericActions()

  const SelectedProductsTotals = useMemo(() => {
    const selectedTotals = products.reduce(
      (acc, product) => {
        if (selectedProducts.includes(product.urlKey)) {
          // Add to original price total
          acc.totalPrice += product.price

          // Add to discounted total if discount exists
          const effectivePrice =
            product.discountedPrice && product.discountedPrice !== 0
              ? product.discountedPrice
              : product.price
          acc.totalDiscountedPrice += effectivePrice
        }
        return acc
      },
      {
        totalPrice: 0,
        totalDiscountedPrice: 0,
      }
    )

    return {
      totalPrice: `$${selectedTotals.totalPrice}`,
      discountedPrice: `$${selectedTotals.totalDiscountedPrice}`,
    }
  }, [products, selectedProducts])

  // Update selectedProducts when products change
  useEffect(() => {
    setSelectedProducts(products.map((product) => product.urlKey))
  }, [products])

  const handleProductSelection = useCallback((urlKey: string) => {
    setSelectedProducts((prevSelected) => {
      return prevSelected.includes(urlKey)
        ? prevSelected.filter((key) => key !== urlKey) // Remove if already selected
        : [...prevSelected, urlKey] // Add if not selected
    })
  }, [])

  const onAtcClick = useCallback(() => {
    const processedSelectedProducts: Record<string, number> = {}

    const {totalPrice, discountedPrice} = SelectedProductsTotals

    const analyticsEvent = {
      actionName: 'TRIGGER_EVENTS_FOR_WEB',
      params: {
        event: 'FBT ATC Clicked',
        eventData: {
          source: id,
          products: selectedProducts,
          discountedPrice,
          totalPrice,
        },
      },
    }

    selectedProducts.forEach((urlKey, index) => {
      processedSelectedProducts[urlKey] = 1
    })
    handleGenericActions([
      {
        actionName: 'MULTI_ATC',
        params: {
          products: processedSelectedProducts,
        },
      },
      analyticsEvent,
    ])
  }, [SelectedProductsTotals, handleGenericActions, id, selectedProducts]) // Ensure it has correct dependencies

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <MultiProductSelectorContainer>
        {products.map((product: any, index: number) => {
          const {
            urlKey = '',
            media,
            priceLabel,
            discountPriceLabel,
            discountedPrice,
            price,
            name,
          } = product || {}

          const showDiscountedPrice =
            discountedPrice && discountedPrice !== 0 && price > discountedPrice

          return (
            <div className="individual-container" key={urlKey + index}>
              <div className="product-card-container">
                <input
                  type="checkbox"
                  checked={selectedProducts.includes(urlKey)}
                  onChange={() => handleProductSelection(urlKey)}
                  disabled={
                    selectedProducts.length === 1 &&
                    selectedProducts.includes(urlKey)
                  }
                />
                <div className="product-details-container">
                  <div className="media-container">
                    <Media
                      media={media}
                      desktopViewWidth={'SMALL'}
                      mobileViewWidth={'SMALL'}
                      aspectRatio={1}
                      customClassName="product-image"
                    />
                  </div>
                  <div className="product-data-container">
                    <div>{name}</div>
                    <div className="pricing">
                      {showDiscountedPrice ? (
                        <div className="price-ctn">
                          <div>
                            <Typography
                              variant={TypographyVariants.X_SMALL}
                              customClassName="price"
                            >
                              {discountPriceLabel}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              variant={TypographyVariants.X_SMALL}
                              customClassName="discounted-price"
                            >
                              {priceLabel}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div className="price-top-container">
                          <Typography
                            variant={TypographyVariants.X_SMALL}
                            customClassName="price price-ctn"
                          >
                            {priceLabel}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {products.length - 1 !== index ? (
                <div className="plus-icon">+</div>
              ) : null}
            </div>
          )
        })}
        <div className="totals-text">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {totalPriceText}
          </Typography>
          <Typography
            customClassName="price"
            variant={TypographyVariants.BODY_BASE_REGULAR}
          >
            {SelectedProductsTotals.discountedPrice}
          </Typography>
          <Typography
            customClassName="discounted-price"
            variant={TypographyVariants.BODY_BASE_REGULAR}
          >
            {SelectedProductsTotals.totalPrice}
          </Typography>
        </div>
        <Button
          variant={variant}
          label={label}
          onClick={onAtcClick}
          customClassName="atc-button"
          size={size}
          fullWidth={true}
          {...rest}
        />
      </MultiProductSelectorContainer>
    </BaseWidgetComponent>
  )
}

export default MultiProductSelector
