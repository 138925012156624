import {RatingsAndReviewsContainer} from './RatingsAndReviews.styles'
import {IRatingsAndReviewsProps} from './RatingsAndReviews.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import RatingsSummary from './RatingsSummary/RatingsSummary'
import ReviewsList from './ReviewsList/ReviewsList'
import {Button, Typography, TypographyVariants} from '@web-components'
import {useCallback} from 'react'
import {useGenericActions} from '../../hooks'

function RatingsAndReviews(props: IRatingsAndReviewsProps) {
  const {id = '', type = '', widgetData, header, layout} = props
  const {
    allReviewsCta,
    showAllReviewCta = true,
    ratingOverview,
    topReviews,
    topReviewsLabel = 'Top Reviews',
  } = widgetData || {}

  const {label, variant, size, actions = []} = allReviewsCta || {}

  const {handleGenericActions} = useGenericActions()

  const handleViewMoreClick = useCallback(() => {
    handleGenericActions(actions)
  }, [actions, handleGenericActions])

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <RatingsAndReviewsContainer>
        <RatingsSummary ratingOverview={ratingOverview} />
        <div className="top-review-label-container">
          <Typography
            customClassName="top-reviews-label"
            variant={TypographyVariants.BODY_BASE_REGULAR}
          >
            {topReviewsLabel}
          </Typography>
        </div>
        <ReviewsList reviews={topReviews} />
        {showAllReviewCta && (
          <Button
            label={label}
            variant={variant}
            size={size}
            fullWidth={true}
            onClick={handleViewMoreClick}
          />
        )}
      </RatingsAndReviewsContainer>
    </BaseWidgetComponent>
  )
}

export default RatingsAndReviews
