import styled from 'styled-components'

export const ProductSwitchContainer = styled.div`
  width: 100%;
  border-top: 1px solid var(--dls-black-color);
  padding: 0;
  @media (min-width: 768px) {
    padding: var(--dls-size-16);
  }
  .row-container {
    display: block;
    width: 100%;
  }

  .cta-grid {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-top: var(--dls-size-16);
    gap: var(--dls-size-8);
    overflow-x: auto;
    padding-bottom: var(--dls-size-8);
    align-items: stretch;
    width: 100%;
    scrollbar-width: none;
  }

  .option-image-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    gap: var(--dls-size-12);
    width: 80px;
    overflow: hidden;

    .image-container {
      display: flex;
      border-radius: var(--dls-size-12);
      padding: var(--dls-size-16);
      width: 80px;
      height: 80px;
      border: 1px solid var(--dls-category-primary-color-500);
      margin-bottom: var(--dls-size-4);
    }

    .cta-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    .cta-label {
      text-align: center;
      width: 100%;
    }

    &[data-isselected='true'] {
      .image-container {
        background-color: var(--dls-category-primary-color-100);
      }
      .cta-label {
        color: var(--dls-category-primary-color-500);
      }
    }
  }

  .option-image-item:hover {
    .image-container {
      background-color: var(--dls-category-primary-color-100);
    }
    .cta-label {
      color: var(--dls-category-primary-color-500);
    }
  }

  .option-image-with-text-item {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    outline: none;
    padding: 0px;
    border-radius: var(--dls-size-16);
    border: 1px solid var(--dls-category-primary-color-500);
    width: 140px;

    .image-container {
      display: flex;
      border-radius: var(--dls-size-16) var(--dls-size-16) 0 0;
      width: 138px;
      overflow: hidden;
    }

    .cta-image {
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    .text-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: var(--dls-size-8) var(--dls-size-16);
    }
    .cta-label {
      width: 100%;
      text-align: center;
    }

    &[data-isselected='true'] {
      background-color: var(--dls-category-primary-color-500);
      .cta-label {
        color: var(--dls-white-color);
      }
    }
  }
  .option-image-with-text-item:hover {
    background-color: var(--dls-category-primary-color-500);
    .cta-label {
      color: var(--dls-white-color);
    }
  }

  .option-text-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    padding: var(--dls-size-12) var(--dls-size-16);
    border-radius: var(--dls-size-8);
    border: 1px solid var(--dls-category-primary-color-500);
    overflow: hidden;

    .cta-label {
      text-align: center;
      width: 100%;
      color: var(--dls-category-primary-color-500);
    }

    &[data-isselected='true'] {
      background-color: var(--dls-category-primary-color-500);
      .cta-label {
        color: var(--dls-white-color);
      }
    }
  }
  .option-text-item:hover {
    background-color: var(--dls-category-primary-color-500);
    .cta-label {
      color: var(--dls-white-color);
    }
  }

  @media screen and (min-width: 786px) {
    .row-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100%;
    }
  }

  .option-image-with-title {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    outline: none;
    padding: 10px;
    border-radius: var(--dls-size-16);
    border: 1px solid rgba(19, 82, 59, 0.2);
    width: 104px;

    cursor: pointer;

    .image-container {
      display: flex;
      border-radius: var(--dls-size-16) var(--dls-size-16) 0 0;
      width: 108px;
      height: 70px;
      overflow: hidden;
    }

    .cta-image {
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    .text-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: var(--dls-size-8) var(--dls-size-16);
    }
    .cta-label {
      width: 100%;
      text-align: center;
      color: var(--dls-brand-primary-color-500);
    }

    &[data-isselected='true'] {
      border: 1px solid var(--dls-black-color);
    }
  }
`
