import React, {useCallback} from 'react'
import {
  TestimonialProductWrapper,
  CardWrapper,
} from './TestimonialProductCard.styles'
import {Media} from '@web-components'
import {Typography} from '@web-components'
import {LeftQuote} from '@web-components'
import {RightQuote} from '@web-components'
import {ReviewRatingStar, TypographyVariants} from '@web-components'
import {useDeviceTypeContext} from '@web-components'
import {
  TestimonialProductCardProps,
  WhyThisWork,
} from '../ProductTestimonials.interface'

function TestimonialProductCard(props: TestimonialProductCardProps) {
  const {product, handleGenericActions} = props || {}
  const {
    personQuote = '',
    personName = '',
    personDesignation = '',
    name = '',
    rating = 0,
    discountText = '',
    media,
    price = 0,
    currency = '₹',
    discountedPrice = 0,
    whyThisWorks = [],
    ctaLabel = 'Learn More',
    cardClickActions,
    ctaActions,
  } = product || {}

  const {isMobile = false} = useDeviceTypeContext()
  const ifProductOnDiscount = !!discountedPrice

  const handleCardClickAction = useCallback(() => {
    const defaultCardActions = [{actionName: 'OPEN_PDP', params: product}]
    handleGenericActions(cardClickActions || defaultCardActions)
  }, [cardClickActions, handleGenericActions, product])

  const handleCtaAction = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      const defaultAction = [{actionName: 'OPEN_PDP', params: product}]
      handleGenericActions(ctaActions || defaultAction)
    },
    [ctaActions, handleGenericActions, product]
  )

  return (
    <TestimonialProductWrapper>
      <div className="quote-wrapper">
        <div className="quote-container">
          <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
            {personQuote}
          </Typography>
        </div>
        <div className="quote-person-description">
          <label className="quote-person-name">{personName}</label>
          {` | `}
          <label className="quote-person-designation">
            {personDesignation}
          </label>
        </div>
        <div className="quote-left">
          <LeftQuote isMobile={isMobile} />
        </div>
        <div className="quote-right">
          <RightQuote isMobile={isMobile} />
        </div>
      </div>

      <CardWrapper onClick={handleCardClickAction}>
        <div className="videoCont">
          <Media
            media={media}
            desktopViewWidth={'THIRD'}
            mobileViewWidth={'THIRD'}
            aspectRatio={1}
            customClassName="autoplayVideo"
          />
        </div>
        <div className="productDetail__new_design">
          <div className="ratingsReview">
            <ReviewRatingStar
              count={5}
              value={Number(rating)}
              size={isMobile ? 14 : 22}
              className="ratingStyle"
            />
          </div>
          <label className="productName">{name}</label>
          <div className="prices">
            <div className="discountP">
              <sup className="rupeeSup">{currency}</sup>
              {ifProductOnDiscount ? discountedPrice : price}
            </div>
            {ifProductOnDiscount && (
              <div className="actualP">
                {currency}
                {price}
              </div>
            )}
            {ifProductOnDiscount && (
              <div className="discountText">{discountText}</div>
            )}
          </div>
          <div className="whyThisWorks">
            {whyThisWorks.map((whyThisWork: WhyThisWork) => {
              const {label, text} = whyThisWork
              return (
                <div key={label} className="why-this-work-item">
                  <span className="key">{label}</span>
                  {text}
                </div>
              )
            })}
          </div>
        </div>
        <div className="cartAdd" onClick={handleCtaAction}>
          <label className="cardAdd-label">
            <Typography variant={TypographyVariants.CTA_LABEL_SMALL}>
              {ctaLabel}
            </Typography>
          </label>
        </div>
      </CardWrapper>
    </TestimonialProductWrapper>
  )
}

export default TestimonialProductCard
