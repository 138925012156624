import {ProductSliderWrapper} from './ProductCardSlider.styles'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import {NoProductsFound, ProductCard, Slider} from '@web-components'
import {useCallback, useMemo} from 'react'
import {Product} from 'src/mono/web-components/components/molecules/ProductCard/ProductCard.types'
import {ProductCardSliderProps} from './ProductCardSlider.interface'

const ProductCardSlider = (props: BaseWidgetProps<ProductCardSliderProps>) => {
  const {layout, id, type, header, widgetData} = props
  const {
    products = [],
    showEmptyState = true,
    emptyState,
    enableQuantityIndicator = false,
    shouldOpenMiniPdp = false,
    miniPdpCta = {},
    productImageAspectRatio = 1,
    isCartUpdating,
    itemBeingAddedToCart,
    cartItems,
    handleCardClick = noop,
    handleCtaClick = noop,
    handleUpdateCartItem = noop,
    sliderConfig,
  } = widgetData || {}

  const {
    aspectRatio = 1,
    slidesToShow = 2,
    showPeek,
    peekVariant = 'MEDIUM',
    slidesToShowDesktop = 4,
    showArrows,
  } = sliderConfig || {}

  const showNoProductsEmptyState = useMemo(
    () => isEmpty(products) && showEmptyState,
    [products, showEmptyState]
  )

  const handleCardClickCallback = useCallback(
    (product: Product) => {
      handleCardClick(product, shouldOpenMiniPdp, miniPdpCta)
    },
    [handleCardClick, shouldOpenMiniPdp, miniPdpCta]
  )

  if (isEmpty(products) && !showEmptyState) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <ProductSliderWrapper>
        <Slider
          aspectRatio={aspectRatio}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          showPeek={showPeek}
          peekVariant={peekVariant}
          showArrows={showArrows}
          itemCount={products.length}
          isMobileView={layout?.isSupportedOnlyForMobile}
        >
          {() =>
            products.map((product: Product, index: number) => {
              return (
                <div className="card-container" key={product.urlKey}>
                  <ProductCard
                    product={product}
                    handleCardClick={handleCardClickCallback}
                    handleCtaClick={handleCtaClick}
                    handleUpdateCartItem={handleUpdateCartItem}
                    enableQuantityIndicator={enableQuantityIndicator}
                    productImageAspectRatio={productImageAspectRatio}
                    isCartUpdating={isCartUpdating}
                    itemBeingAddedToCart={itemBeingAddedToCart}
                    cartItems={cartItems}
                  />
                </div>
              )
            })
          }
        </Slider>
      </ProductSliderWrapper>
      {showNoProductsEmptyState && <NoProductsFound emptyState={emptyState} />}
    </BaseWidgetComponent>
  )
}

export default ProductCardSlider
