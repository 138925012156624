import styled from 'styled-components'

export const MediaWithReadMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;

  @media (min-width: 768px) {
    width: 90%;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-32);
    @media (min-width: 768px) {
      flex-direction: row;

      margin: auto;
      gap: var(--dls-size-16);
    }
  }
`
