import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import React, {useCallback} from 'react'
import {
  Media,
  useDeviceTypeContext,
  Typography,
  TypographyVariants,
  CtaWithAnimation,
} from '@web-components'

import {BaseWidgetComponent} from '../BaseWidget'
import {MediaBackgroundWithCtaWrapper} from './MediaBackgroundWithCta.styles'
import {useGenericActions} from '../../hooks'
import {IHomePageBannerProps} from './MediaBackgroundWithCta.types'

const MediaBackgroundWithCta = ({
  widgetData,
  type,
  layout,
  id,
}: BaseWidgetProps<IHomePageBannerProps>) => {
  const {title = '', media, aspectRatio, cta} = widgetData || {}
  const {label, actions, variant = 'PRIMARY', icon = ''} = cta || {}

  const {isMobile = false} = useDeviceTypeContext()
  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (actions: GenericAction[]) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id, label]
  )

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <MediaBackgroundWithCtaWrapper>
        <Media
          media={media}
          aspectRatio={aspectRatio}
          customClassName="banner"
        />
        <div className="banner-content">
          <Typography
            variant={
              isMobile
                ? TypographyVariants.HEADING_LARGE_REGULAR
                : TypographyVariants.HEADING_2XLARGE_REGULAR
            }
          >
            <span dangerouslySetInnerHTML={{__html: title}} />
          </Typography>
          <div className="banner-btn-wrapper">
            <CtaWithAnimation
              onClick={() => {
                handleClick(actions)
              }}
              variant={variant}
              icon={icon}
            >
              {label}
            </CtaWithAnimation>
          </div>
        </div>
      </MediaBackgroundWithCtaWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaBackgroundWithCta
