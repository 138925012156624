import React from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {MediaWithProgressSliderWrapper} from './MediaWithProgressSlider.styles'
import {Carousel} from '@web-components'
import {
  IMediaWithProgressSlider,
  Item,
} from './MediaWithProgressSlider.interface'
import MediaWithProgress from './MediaWithProgress/MediaWithProgress'

const MediaWithProgressSlider = (
  props: BaseWidgetProps<IMediaWithProgressSlider>
) => {
  const {widgetData, type, id, layout, header} = props
  const {items = [], aspectRatio = 1, carouselConfig} = widgetData || {}

  const {
    showDots = false,
    showArrows = true,
    slidesToShow = 1,
    slidesToShowDesktop,
    infinite,
    arrowPosition,
    autoSlideInterval = 5000,
    autoSlide,
  } = carouselConfig || {}

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithProgressSliderWrapper>
        <Carousel
          showDots={showDots}
          showArrows={showArrows}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          infinite={infinite}
          arrowPosition={arrowPosition}
          autoSlide={autoSlide}
          autoSlideInterval={autoSlideInterval}
        >
          {items.map((item: Item, index: number) => {
            return (
              <MediaWithProgress
                key={index}
                widgetData={item}
                aspectRatio={aspectRatio}
              />
            )
          })}
        </Carousel>
      </MediaWithProgressSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithProgressSlider
