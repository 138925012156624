import React from 'react'
import {ReviewImageSliderWrapper} from './ReviewImageSlider.styles'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import ReviewWithImage from './ReviewWithImage/ReviewWithImage'

import {Slider} from '@web-components'
import {IReviewItem, IReviewWithImageProps} from './ReviewImageSlider.interface'
import RatingComponent from './CustomerRating/CustomerRating'

const ReviewImageSlider = (props: BaseWidgetProps<IReviewWithImageProps>) => {
  const {widgetData, type, layout, id, header} = props || {}

  const {items = [], customerRating, sliderConfig} = widgetData || {}
  const {
    avatarImages = [],
    ratingStarCount = 5,
    ratingStarValue = 5,
    ratingNumber = '5.0',
    bottomText = '',
  } = customerRating || {}

  const {
    slidesToShow = 1.2,
    aspectRatio = 1,
    peekVariant = 'MEDIUM',
    showArrows = false,
    slidesToShowDesktop = 3,
  } = sliderConfig || {}

  const ratingComponentProps = {
    avatarImages,
    ratingStarCount,
    ratingStarValue,
    ratingNumber,
    bottomText,
  }

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <ReviewImageSliderWrapper>
        <BaseWidgetComponent.Header
          header={header}
          Component={() => <RatingComponent {...ratingComponentProps} />}
        />

        <Slider
          aspectRatio={aspectRatio}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          showArrows={showArrows}
          peekVariant={peekVariant}
          itemCount={items.length}
          isMobileView={layout?.isSupportedOnlyForMobile}
        >
          {() =>
            items.map((item: IReviewItem, index: number) => (
              <ReviewWithImage
                data={item}
                key={index}
                aspectRatio={aspectRatio}
              />
            ))
          }
        </Slider>
      </ReviewImageSliderWrapper>
    </BaseWidgetComponent>
  )
}

export default ReviewImageSlider
