import styled from 'styled-components'

export const CalloutWithImageContainer = styled.div`
  padding: 12px var(--dls-size-16);
  background-color: var(--dls-success-color-100);
  display: flex;
  align-items: center;

  &.INFO {
    background-color: var(--dls-success-color-100);
    color: var(--dls-success-color-500);
  }
  &.WARNING {
    background-color: var(--dls-warning-color-100);
    color: var(--dls-warning-color-500);
  }
  &.DANGER {
    background-color: var(--dls-error-color-100);
    color: var(--dls-error-color-500);
  }
  &.BRAND {
    background-color: var(--dls-brand-primary-color-100);
    color: var(--dls-brand-primary-color-500);
  }

  .callout-image-container {
    margin-right: 16px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
`
