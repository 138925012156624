import {useCartStore} from '@mosaic-wellness/redux-action-library'
import {useCartLoadingContext} from 'src/components/shared/src/ComponentsV2/Context/CartUpdatingContext'
import HorizontalProductCardGrid from './HorizontalProductCardGrid'
import noop from 'lodash/noop'
import useProductCardActions from '../../hooks/useProductCardActions'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {HorizontalProductCardGridProps} from './HorizontalProductCardGrid.interface'

const HorizontalProductCardGridContainer = (
  props: BaseWidgetProps<HorizontalProductCardGridProps>
) => {
  const {type, widgetData, header, layout, id} = props || {}

  const {isCartUpdating, itemBeingAddedToCart} = useCartLoadingContext()
  const {cart} = useCartStore()
  const {checkoutInfo} = cart || {}
  const {items} = checkoutInfo || {}

  const {
    handleCardClick = noop,
    handleCtaClick = noop,
    handleUpdateCartItem = noop,
  } = useProductCardActions({source: id})

  return (
    <HorizontalProductCardGrid
      id={id}
      type={type}
      header={header}
      layout={layout}
      widgetData={{
        ...widgetData,
        isCartUpdating,
        itemBeingAddedToCart,
        cartItems: items,
        handleCardClick,
        handleCtaClick,
        handleUpdateCartItem,
      }}
    />
  )
}

export default HorizontalProductCardGridContainer
