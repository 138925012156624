import styled from 'styled-components'

export const MediaWithReadMoreCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-16);
  cursor: pointer;
  border-radius: var(--dls-size-24);
  padding: var(--dls-size-16);
  max-width: 420px;

  &:hover {
    background-color: var(--dls-white-color);
  }
  .media-container {
    border-radius: var(--dls-size-16);
    overflow: hidden;
  }

  .media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-16);
    color: var(--dls-brand-primary-color-500);
  }
  .card-cta {
    width: max-content;
    text-decoration: none;
    padding-bottom: 2px;
    color: var(--dls-brand-primary-color-500);
    border-bottom: 1px solid var(--dls-brand-primary-color-500);
  }

  .info-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
