import {
  RatingStar,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import {IRatingsSummaryProps} from './RatingsSummary.interface'
import {RatingsSummaryContainer} from './RatingsSummary.styles'

function RatingsSummary(props: IRatingsSummaryProps) {
  const {ratingOverview} = props
  const {overAllRating, ratingsStats, totalNumberOfReviews} =
    ratingOverview || {}

  return (
    <RatingsSummaryContainer>
      <div className="overall-rating-container">
        <div className="overall-rating">
          <div className="rating-star-container">
            <ReviewRatingStar
              className="ratings-stars"
              value={Number(overAllRating)}
              count={5}
              size={20}
            />
          </div>
          <Typography
            variant={TypographyVariants.BODY_BASE_REGULAR}
            customClassName="overall-rating-text"
          >
            {overAllRating} out of 5
          </Typography>
          {/* <RatingStar
            height={24}
            width={24}
            stroke="none"
            fill="var(--dls-brand-primary-color-500)"
          /> */}
        </div>
        <Typography
          variant={TypographyVariants.TAG_REGULAR}
          customClassName="total-reviews-text"
        >
          Base on {totalNumberOfReviews} Reviews
        </Typography>
      </div>
      <div className="rating-stats-container">
        {ratingsStats?.map((ratingStat, index) => {
          const {
            fillerColor = '',
            numberOfReviews = 0,
            rating = 0,
            numberOfReviewsLabel = '',
          } = ratingStat || {}

          const figure = Math.ceil(
            (numberOfReviews / totalNumberOfReviews) * 100
          )

          return (
            <div className="rating-bar-container" key={index}>
              <div className="rating-number-container">
                {/* <Typography variant={TypographyVariants.TAG_REGULAR}>
                  {rating}
                </Typography> */}
                <div className="rating-star-container">
                  <ReviewRatingStar
                    className="ratings-stars"
                    value={Number(rating)}
                    count={5}
                    size={20}
                  />
                </div>
              </div>
              <div className="strip">
                <div
                  className="filled-strip"
                  style={{background: fillerColor, width: figure}}
                />
              </div>
              <div className="trailing-text-container">
                <Typography
                  variant={TypographyVariants.TAG_REGULAR}
                  customClassName="trailing-text"
                >
                  {numberOfReviewsLabel}
                </Typography>
              </div>
            </div>
          )
        })}
      </div>
    </RatingsSummaryContainer>
  )
}

export default RatingsSummary
