import styled from 'styled-components'

export const SocialReviewListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--dls-size-16);
  gap: var(--dls-size-16);
  position: relative;

  .reviews-grid {
    display: grid;
    gap: var(--dls-size-16);
  }

  .review-card-wrapper {
    display: grid;
    grid-template-rows: 1fr;
    transition: grid-template-rows 0.5s ease-in-out;

    &.hidden {
      grid-template-rows: 0fr;
    }
  }

  .review-card-inner {
    overflow: hidden;
  }
`
