import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {IHighlightItem, IProductSummaryProps} from './ProductSummary.interface'
import {ProductSummaryContainer} from './ProductSummary.styles'
import {
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useCallback, useMemo} from 'react'
import {useGenericActions} from '../../hooks'
import {GenericAction} from '@mosaic-wellness/fe-types'

function ProductSummary(props: IProductSummaryProps) {
  const {id = '', type = '', widgetData, layout} = props
  const {productSummary, ratingClickAction} = widgetData || {}
  const {
    name = '',
    rating = '',
    reviewCount = '',
    showTaxesLabel = true,
    taxesLabel = 'Incl of all taxes',
    unitsSold = '',
    discountText = '',
    discountTagImage = 'https://i.mscwlns.co/media/misc/others/Offer%20bg_4vdutz.png?tr=w-50',
    actualPriceLabel = '',
    discountedPriceLabel = '',
    highlights = [],
  } = productSummary || {}
  const {genericAction, handleGenericActions} = useGenericActions()

  const onRatingsClick = useCallback(
    (actions: GenericAction[]) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label: name,
            ...params,
          },
        },
      }
      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id, name]
  )

  if (isEmpty(productSummary)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <ProductSummaryContainer>
        <div className="pricing-and-name-details">
          <Typography
            variant={TypographyVariants.HEADING_SMALL_BOLD}
            customClassName="product-name"
          >
            {name}
          </Typography>
          <div className="price">
            <>
              {discountedPriceLabel ? (
                <Typography variant={TypographyVariants.HEADING_SMALL_BOLD}>
                  {discountedPriceLabel}
                </Typography>
              ) : null}
              <>
                {actualPriceLabel ? (
                  <Typography
                    variant={TypographyVariants.BODY_BASE_REGULAR}
                    customClassName="stricked-price"
                  >
                    {actualPriceLabel}
                  </Typography>
                ) : null}
                {discountText && discountTagImage && (
                  <div className="discount-tag">
                    <OptimizedImage
                      source={discountTagImage}
                      aspectHeight={'16'}
                      aspectWidth={'60'}
                    />
                    <Typography
                      customClassName="discount-tag-text"
                      variant={TypographyVariants.TAG_BOLD}
                    >
                      {discountText}
                    </Typography>
                  </div>
                )}
              </>
            </>
          </div>
          {showTaxesLabel && (
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="tax-label"
            >
              {taxesLabel}
            </Typography>
          )}
        </div>
        <div className="rating-and-price-details">
          <div
            className="rating-and-price-details-item"
            onClick={() => onRatingsClick(ratingClickAction.actions)}
          >
            <div className="ratings">
              <ReviewRatingStar
                value={Number(rating)}
                count={5}
                size={16}
                className="rating-container"
              />
              <Typography variant={TypographyVariants.TAG_REGULAR}>
                {reviewCount} reviews
              </Typography>
            </div>
          </div>
          <div className="divider"></div>
          {!!unitsSold ? (
            <div className="rating-and-price-details-item">
              <Typography variant={TypographyVariants.TAG_REGULAR}>
                {unitsSold} units sold
              </Typography>
            </div>
          ) : null}
        </div>

        {highlights && (
          <ul className="product-description-details">
            {highlights.map((item: IHighlightItem, index: number) => (
              <li key={index} className="product-description-item">
                <Typography
                  key={`description-${index}`}
                  variant={TypographyVariants.BODY_BASE_REGULAR}
                >
                  <span dangerouslySetInnerHTML={{__html: item.text}} />
                </Typography>
              </li>
            ))}
          </ul>
        )}
      </ProductSummaryContainer>
    </BaseWidgetComponent>
  )
}

export default ProductSummary
