import {useCallback, useState} from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {ISocialReviewListProps} from './SocialReviewList.interface'
import {Button} from '@web-components'
import {SocialReviewListContainer} from './SocialReviewList.styles'
import SocialReviewCard from './SocialReviewCard/SocialReviewCard'
import isEmpty from 'lodash/isEmpty'
import {useGenericActions} from '../../hooks'

function SocialReviewList(props: ISocialReviewListProps) {
  const {type, widgetData, header, layout, id} = props
  const {reviews, cta, actions, showButton = true} = widgetData || {}
  const {label = '', variant, size} = cta || {}
  const {handleGenericActions} = useGenericActions()

  const [isSocialReviewExpanded, setIsSocialReviewExpanded] = useState(false)

  const handleCtaClick = useCallback(() => {
    if (!isEmpty(actions)) {
      handleGenericActions(actions)
      return
    }
    setIsSocialReviewExpanded(true)
  }, [actions, handleGenericActions])

  if (isEmpty(reviews)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <SocialReviewListContainer>
        <div className="reviews-grid">
          {reviews.map((review, index) => (
            <div
              key={index}
              className={`review-card-wrapper ${
                !isSocialReviewExpanded && index > 0 ? 'hidden' : ''
              }`}
            >
              <div className="review-card-inner">
                <SocialReviewCard review={review} />
              </div>
            </div>
          ))}
        </div>
        {showButton && !isSocialReviewExpanded && (
          <Button
            label={label}
            variant={variant}
            size={size}
            fullWidth={true}
            onClick={handleCtaClick}
          />
        )}
      </SocialReviewListContainer>
    </BaseWidgetComponent>
  )
}

export default SocialReviewList
