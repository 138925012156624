import styled from 'styled-components'

export const ProductSliderWrapper = styled.div`
  .card-container {
    a {
      border: 1px solid var(--dls-divider-light-color);
      border-radius: var(--dls-size-16);
    }
  }
`
