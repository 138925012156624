import styled from 'styled-components'

export const MediaWithFooterSliderContainer = styled.div`
  width: 100%;

  .modal-content {
    max-width: 500px;
    width: 100%;
    height: auto;
    padding: var(--dls-size-16);
  }
  .expanded-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .expanded-description {
    color: var(--dls-secondary-text-colors);
    margin-top: var(--dls-size-16);
  }

  .mini-ctas-container {
    margin-top: var(--dls-size-8);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: var(--dls-size-4);
  }
`
