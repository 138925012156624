import React, {useCallback} from 'react'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'

import {Slider, CatalogCard} from '@web-components'
import {useGenericActions} from '../../hooks'
import {CatalogCardsContainer} from './CatalogCards.styles'
import {ICatalogCards, ICatalogProduct} from './CatalogCards.interface'
import isEmpty from 'lodash/isEmpty'

const CatalogCardSlider = (props: BaseWidgetProps<ICatalogCards>) => {
  const {widgetData, type, layout, id, header} = props
  const {products = [], sliderConfig} = widgetData || {}

  const {
    aspectRatio = 1,
    slidesToShow,
    showPeek,
    peekVariant,
    slidesToShowDesktop,
    showArrows,
    arrowPosition = 'inside',
  } = sliderConfig || {}

  const {handleGenericActions} = useGenericActions()

  const handleCtaClick = useCallback(
    (actions: GenericAction[] | undefined, product: ICatalogProduct) => {
      handleGenericActions(
        actions || [
          {
            actionName: 'ADD_TO_CART',
            params: {...product, source: id},
          },
        ]
      )
    },
    [handleGenericActions, id]
  )

  const handleCardClick = useCallback(
    (actions: GenericAction[] | undefined, product: ICatalogProduct) => {
      const defaultAction = [
        {
          actionName: 'OPEN_PDP',
          params: {...product, source: id},
        },
      ]
      handleGenericActions(actions || defaultAction)
    },
    [handleGenericActions, id]
  )

  if (isEmpty(products)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <CatalogCardsContainer>
        <Slider
          aspectRatio={aspectRatio}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          showPeek={showPeek}
          peekVariant={peekVariant}
          showArrows={showArrows}
          itemCount={products.length}
          arrowPosition={arrowPosition}
          isMobileView={layout?.isSupportedOnlyForMobile}
        >
          {() =>
            products.map((product, index) => (
              <CatalogCard
                key={index}
                product={product}
                handleCardClick={handleCardClick}
                handleCtaClick={handleCtaClick}
              />
            ))
          }
        </Slider>
      </CatalogCardsContainer>
    </BaseWidgetComponent>
  )
}

export default CatalogCardSlider
