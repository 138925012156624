import React, {useMemo} from 'react'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {isEmpty} from 'lodash'
import {
  StyledDesktopExpandedMediaContainer,
  StyledMobileMainItem,
  StyledSliderItem,
} from './ExpandedMedia.styles'
import {
  Media,
  MediaPreviewModal,
  Slider,
  useDeviceTypeContext,
} from '@web-components'
import {ExpandedMediaProps} from './ExpandedMedia.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import useExpandedMedia from './useExpandedMedia'

function ExpandedMedia(props: BaseWidgetProps<ExpandedMediaProps>) {
  const {widgetData, id, header, layout, type} = props || {}
  const {items = [], sliderConfig, previewModal = {}} = widgetData || {}

  const {
    aspectRatio = 1,
    slidesToShow,
    showPeek,
    peekVariant,
    slidesToShowDesktop,
    showArrows,
  } = sliderConfig || {}

  const {isMobile} = useDeviceTypeContext()

  const {
    sliderRef,
    activeSlide,
    handleSliderItemClick,
    previewImage,
    showPreviewModal,
    closePreviewModal,
    handleDesktopItemClick,
  } = useExpandedMedia(widgetData)

  if (isEmpty(items)) {
    return null
  }

  const activeSlideProps = useMemo(() => {
    return {
      ...items?.[activeSlide]?.media,
      controls: true,
      autoplay: true,
      muted: false,
    }
  }, [activeSlide, items])

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      {isMobile ? (
        <>
          <StyledMobileMainItem ref={sliderRef} onClick={previewImage}>
            <Media
              media={activeSlideProps}
              desktopViewWidth={'FULL'}
              mobileViewWidth={'FULL'}
              aspectRatio={items?.[activeSlide]?.aspectRatio}
              customClassName="tile-image"
            />
          </StyledMobileMainItem>
          <Slider
            aspectRatio={aspectRatio}
            slidesToShow={slidesToShow}
            slidesToShowDesktop={slidesToShowDesktop}
            showPeek={showPeek}
            peekVariant={peekVariant}
            showArrows={showArrows}
            itemCount={items.length}
            isMobileView={layout?.isSupportedOnlyForMobile}
            activeSlide={activeSlide}
          >
            {() =>
              items.map((item, index: number) => {
                const mediaProps = {
                  ...item.media,
                  autoplay: false,
                }
                return (
                  <StyledSliderItem
                    key={'slider_item_' + index}
                    data-isActive={index === activeSlide}
                    onClick={handleSliderItemClick(index)}
                  >
                    <Media
                      media={mediaProps}
                      desktopViewWidth={'THIRD'}
                      mobileViewWidth={'SMALL'}
                      aspectRatio={item?.aspectRatio}
                      customClassName="tile-image"
                    />
                  </StyledSliderItem>
                )
              })
            }
          </Slider>
        </>
      ) : (
        <StyledDesktopExpandedMediaContainer>
          <Media
            media={items?.[0]?.media}
            aspectRatio={items?.[0]?.aspectRatio}
            customClassName="tile-image"
            onClick={handleDesktopItemClick(0)}
          />
          <div className="tiles-container">
            {items.map((item: any, index: number) => {
              if (index === 0) {
                return null
              }
              const mediaProps = {
                ...item?.media,
                controls: true,
                autoplay: false,
              }
              return (
                <Media
                  key={'media_item_' + index}
                  media={mediaProps}
                  aspectRatio={item?.aspectRatio}
                  customClassName="tile-image"
                  onClick={handleDesktopItemClick(index)}
                />
              )
            })}
          </div>
        </StyledDesktopExpandedMediaContainer>
      )}
      {showPreviewModal && (
        <MediaPreviewModal
          showModal={showPreviewModal}
          onClose={closePreviewModal}
          items={items}
          carouselConfig={previewModal?.carouselConfig || {}}
          sliderConfig={previewModal?.sliderConfig || {}}
          defaultSlide={activeSlide}
          headerTitle={previewModal?.headerTitle}
        />
      )}
    </BaseWidgetComponent>
  )
}

export default ExpandedMedia
