import {
  OptimizedImage,
  ReviewRatingStar,
  Typography,
  TypographyVariants,
} from '@web-components'
import {AvatarItem, ICustomerRatingProps} from './CustomerRating.interface'
import {CustomerRatingWrapper} from './CustomerRating.styles'

const RatingComponent = (props: ICustomerRatingProps) => {
  const {
    avatarImages = [],
    ratingStarCount = 5,
    ratingStarValue = 5,
    ratingNumber = '5.0',
    bottomText = '',
  } = props
  return (
    <CustomerRatingWrapper>
      <div className="avatar-wrapper">
        {avatarImages.map((item: AvatarItem, index: number) => {
          const {source, altText} = item || {}
          return (
            <div className="avatar-container" key={index}>
              <OptimizedImage
                source={source}
                altText={altText}
                customClassName="avatar-image"
              />
            </div>
          )
        })}
      </div>
      <div className="rating-wrapper">
        <div className="rating-container">
          <span className="rating-number">
            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {ratingNumber}
            </Typography>
          </span>
          <ReviewRatingStar
            count={ratingStarCount}
            value={ratingStarValue}
            size={22}
            className="ratingStyle"
            color="#F6CA4C"
            activeColor="#F6CA4C"
          />
        </div>
        <div className="customer-count">
          <Typography variant={TypographyVariants.TAG_REGULAR}>
            {bottomText}
          </Typography>
        </div>
      </div>
    </CustomerRatingWrapper>
  )
}

export default RatingComponent
