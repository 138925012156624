import styled from 'styled-components'

export const RatingsSummaryContainer = styled.div`
  color: var(--dls-primary-text-colors);
  padding: 20px;
  max-width: 400px;
  margin: auto;
  .overall-rating-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .rating-star-container {
    display: flex;
    align-items: center;
    gap: var(--dls-size-8);
  }
  .ratings-stars {
    display: flex;
  }
  .overall-rating {
    display: flex;
    align-items: center;
    gap: var(--dls-size-4);
  }

  .overall-rating-text {
    line-height: 1;
  }

  .total-reviews-text {
  }

  .rating-stats-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-4);
    padding: var(--dls-size-16) var(--dls-size-32);
  }

  .rating-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--dls-size-8);

    --figure: attr(data-figure);
    --fillcolor: attr(data-fillcolor);

    .filled-strip {
      height: 14px;
      width: var(--figure);
      background-color: var(--fillcolor);
    }
  }

  .rating-number-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--dls-size-16);
  }

  .rating-number {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .strip {
    background-color: rgba(224, 224, 224, 0.5);
    height: 14px;
    flex: 1;
  }

  .trailing-text-container {
    text-align: left;
    width: 36px;
  }

  .trailing-text {
    color: var(--dls-secondary-text-colors);
  }
`
