import styled from 'styled-components'

export const MediaWithListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-items: center;
  gap: var(--dls-size-32);

  @media (min-width: 768px) {
    width: 65%;
    gap: var(--dls-size-64);
    flex-direction: row;
  }

  .media-content {
    width: 100%;
    border-radius: var(--dls-size-16);
    overflow: hidden;

    @media (min-width: 768px) {
      width: 70%;
    }
  }

  .media {
    width: 100%;
    object-fit: cover;
  }

  .list-content {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
    width: 100%;
  }

  .list-element {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfdad5;
    cursor: pointer;
  }

  .left-content {
    display: flex;
    justify-content: space-between;
    gap: var(--dls-size-8);
  }

  .btn-icon-wrapper {
    height: var(--dls-size-48);
    width: var(--dls-size-48);
    border-radius: 100%;
    padding: var(--dls-size-8);
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow-icon-bottom {
      position: absolute;
      top: 42px;
      right: 42px;
    }

    svg {
      position: absolute;
      top: 10px;

      right: 10px;
      transform: translate(0, 0);
      transition: transform 0.4s ease;
    }
  }

  .list-element:hover {
    border-bottom: 1px solid var(--dls-black-color);

    svg {
      transform: translate(52px, -52px);
    }

    .arrow-icon-bottom {
      transform: translate(-10px, 10px);
      opacity: 1;
    }
  }
`
