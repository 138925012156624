import {IMediaWithHeaderCardProps} from './MediaWithFooterCard.interface'
import {
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {MediaWithFooterCardContainer} from './MediaWithFooterCard.styles'
import {useCallback, useEffect, useRef, useState} from 'react'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'

function MediaWithFooterCard(props: IMediaWithHeaderCardProps) {
  const {
    header = '',
    media,
    description = '',
    readMoreLabel = '',
    aspectRatio = 1,
    miniCtas = [],
    onCardClick = noop,
    handleAction = noop,
  } = props

  const [showReadMoreButton, setShowReadMoreButton] = useState(false)
  const [showFullText, setShowFullText] = useState(false)
  const descriptionRef = useRef<HTMLElement>()

  useEffect(() => {
    if (!descriptionRef.current) {
      return
    }

    if (
      descriptionRef.current.offsetHeight <
        descriptionRef.current.scrollHeight ||
      descriptionRef.current.offsetWidth < descriptionRef.current.scrollWidth
    ) {
      setShowReadMoreButton(true)
    }
  }, [])

  const handleReadMoreClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    setShowFullText(true)
  }, [])

  const handleCardClick = useCallback(() => {
    onCardClick({description, media})
  }, [description, media, onCardClick])

  if (!media) {
    return null
  }

  return (
    <MediaWithFooterCardContainer onClick={handleCardClick}>
      <div className="card-image-container">
        <Media
          media={media}
          desktopViewWidth={'THIRD'}
          mobileViewWidth={'THIRD'}
          aspectRatio={aspectRatio}
        />
      </div>
      {!!header ? (
        <div className="header-container">
          <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
            <span
              dangerouslySetInnerHTML={{
                __html: header,
              }}
            />
          </Typography>
        </div>
      ) : null}
      {!!description ? (
        <div className="description-container">
          <Typography
            variant={TypographyVariants.BODY_BASE_REGULAR}
            customClassName={`description-text ${
              !showFullText && 'line-clamp-4'
            }`}
            ref={descriptionRef}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Typography>
          {showReadMoreButton && !showFullText && (
            <button className="read-more-button" onClick={handleReadMoreClick}>
              <Typography variant={TypographyVariants.TAG_BOLD}>
                ... {readMoreLabel}
              </Typography>
            </button>
          )}
          {!isEmpty(miniCtas) ? (
            <div className="mini-ctas-container">
              {miniCtas.map((minicta, index) => (
                <div
                  className="mini-cta"
                  onClick={() => handleAction(minicta.actions)}
                  key={index}
                >
                  <OptimizedImage
                    source={minicta.icon}
                    aspectHeight="20"
                    aspectWidth="20"
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </MediaWithFooterCardContainer>
  )
}

export default MediaWithFooterCard
