import styled from 'styled-components'

export const DesktopCategoryPaletteContainer = styled.a<{
  backgroundColor: string
}>`
  display: flex;
  justify-content: space-around;
  text-decoration: none;
  color: var(--dls-black-color);
  background-color: ${({backgroundColor}) =>
    backgroundColor || 'var(--dls-white-color)'};
  border-radius: var(--dls-size-16);
  padding: var(--dls-size-16) var(--dls-size-16) var(--dls-size-0)
    var(--dls-size-16);
  overflow: hidden;

  .media-wrapper {
    width: 120px;
    height: auto;
  }
  .content-container {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    flex-direction: column;
    gap: var(--dls-size-8);
    padding: var(--dls-size-16);
  }
  .categoryName {
    display: inline;
    margin-right: var(--dls-size-16);
  }

  .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (min-width: 786px) {
    display: block;
    border: 1px solid var(--dls-white-color);
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
    background-color: var(--dls-white-color);
    color: var(--dls-black-color);
    padding: 0;
    &:hover {
      color: var(--dls-white-color);
      background-color: var(--dls-black-color);

      .chevron-icon path {
        fill: white;
      }
    }
    .media-wrapper {
      width: 100%;
      height: auto;
    }
    .media-class {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: ${({backgroundColor}) =>
        backgroundColor || 'var(--dls-white-color)'};
    }
  }
`
