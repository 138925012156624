import styled from 'styled-components'

export const CustomerRatingWrapper = styled.div`
  display: flex;
  gap: var(--dls-size-16);
  align-items: center;
  padding: var(--dls-size-16);

  .avatar-wrapper {
    display: flex;
  }

  .avatar-container {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: -4.5px;
  }

  .avatar-image {
    width: 100%;
  }

  .rating-container {
    display: flex;
    gap: 5px;
  }
  .rating-number {
    background-color: var(--dls-brand-primary-color-500);
    padding: 1px var(--dls-size-8);
    border-radius: var(--dls-size-16);
    color: var(--dls-white-color);
  }

  .rating {
    display: flex;
  }
`
