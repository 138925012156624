import React, {useCallback, useState} from 'react'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'

import {Slider} from '@web-components'
import {MediaWithProductOverlayContainer} from './MediaWithProductOverlay.styles'
import MediaWithProductOverlayCard from './MediaWithProductOverlayCard/MediaWithProductOverlayCard'
import {MediaWithProductOverlayProps} from './MediaWithProductOverlay.interface'
import {useGenericActions} from '../../hooks'

function VideoImageProductCardSlider({
  widgetData,
  id,
  header,
  layout,
  type,
}: BaseWidgetProps<MediaWithProductOverlayProps>) {
  const {
    items = [],
    sliderConfig,
    videoMutedIcon = '',
    videoUnMutedIcon = '',
  } = widgetData

  const {
    aspectRatio = 0.56,
    slidesToShow = 2,
    showPeek = true,
    peekVariant = 'LARGE',
    slidesToShowDesktop,
    showArrows,
  } = sliderConfig || {}

  // State to track the currently active video
  const [activeVideoIndex, setActiveVideoIndex] = useState<number | null>(null)

  const {handleGenericActions} = useGenericActions()

  const handleAction = useCallback(
    (actions: GenericAction[]) => {
      handleGenericActions(actions)
    },
    [handleGenericActions]
  )

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithProductOverlayContainer>
        <Slider
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          aspectRatio={aspectRatio}
          showPeek={showPeek}
          peekVariant={peekVariant}
          showArrows={showArrows}
          itemCount={items.length}
          isMobileView={layout?.isSupportedOnlyForMobile}
        >
          {() =>
            items.map((item, index) => {
              return (
                <MediaWithProductOverlayCard
                  product={item}
                  aspectRatio={aspectRatio}
                  videoMutedIcon={videoMutedIcon}
                  videoUnMutedIcon={videoUnMutedIcon}
                  handleAction={handleAction}
                  key={index}
                  isActive={activeVideoIndex === index}
                  setActiveVideo={() => setActiveVideoIndex(index)} // Set the current active video
                />
              )
            })
          }
        </Slider>
      </MediaWithProductOverlayContainer>
    </BaseWidgetComponent>
  )
}

export default VideoImageProductCardSlider
