import styled from 'styled-components'

export const MediaInfoWrapper = styled.div`
  .media-info {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
    width: 100%;
    padding: var(--dls-size-24);
    margin-top: 0;
  }

  .media-info-image {
    border-radius: var(--dls-size-32);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .media-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .media-info-description {
    display: flex;
    padding: var(--dls-size-32) 0;
  }

  .media-info-divider {
    height: 1px;
    background-color: white;
    width: 100%;
  }

  .media-info-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: var(--dls-size-24);
    width: 100%;
  }

  .media-info-name {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-16);
    width: 100%;
    align-items: start;
  }

  .media-info-number {
    display: flex;
    align-items: self-end;
  }

  @media screen and (min-width: 786px) {
    .media-info {
      display: flex;
      flex-direction: row;
      gap: var(--dls-size-80);
      align-items: center;
      width: 68%;
      margin: 0 auto;
      margin-top: var(--dls-size-48);
    }
    .media-info-image {
      width: 500px;
      height: 500px;
    }
  }
`
