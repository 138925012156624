import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {Item, MediaGridProps} from './MediaGrid.interface'
import {TilesWrapper} from './MediaGrid.styles'
import {useTiles} from './useMediaGrid'
import MediaTile from './MediaTile'

function MediaGrid({
  widgetData,
  id,
  header,
  layout,
  type,
}: BaseWidgetProps<MediaGridProps>) {
  const {
    noOfColumns,
    handleCtaClick,
    items,
    aspectRatio = 1,
    scaleFullWidthDesktop = false,
  } = useTiles({id, widgetData})

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <TilesWrapper
        noOfColumns={noOfColumns}
        scaleFullWidthDesktop={scaleFullWidthDesktop}
      >
        <div className="tiles-container">
          {items.map((item: Item, index: number) => {
            return (
              <MediaTile
                key={index}
                data={item}
                aspectRatio={aspectRatio}
                handleCtaClick={handleCtaClick}
              />
            )
          })}
        </div>
      </TilesWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaGrid
