import {Media, Typography, TypographyVariants} from '@web-components'
import React from 'react'
import {IMediaTile} from './MediaGrid.interface'

const MediaTile = (props: IMediaTile) => {
  const {data, aspectRatio, handleCtaClick} = props
  const {media, label, actions, slug = '#'} = data || {}

  return (
    <a
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (actions) {
          handleCtaClick(actions, label)
        }
      }}
      className={`tile-container ${actions ? 'cursor-pointer' : ''}`}
      href={String(slug)}
    >
      <Media
        media={media}
        desktopViewWidth={'THIRD'}
        mobileViewWidth={'THIRD'}
        aspectRatio={aspectRatio}
        customClassName="tile-image"
      />
      {label && (
        <div className="text-container">
          <Typography variant={TypographyVariants.X_SMALL}>
            <span dangerouslySetInnerHTML={{__html: label}} />
          </Typography>
        </div>
      )}
    </a>
  )
}

export default MediaTile
