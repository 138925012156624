import {InformationGridStripContainer} from './InformationGridStrip.styles'
import {IInformationGridStripProps} from './InformationGridStrip.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import isEmpty from 'lodash/isEmpty'
import {Typography, TypographyVariants} from '@web-components'

function InformationGridStrip(props: IInformationGridStripProps) {
  const {id, type, widgetData, layout} = props
  const {items = [], category = 'hair'} = widgetData || {}

  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent
      layout={{
        background: 'var(--dls-brand-primary-color-100)',
        borderRadius: '0px',
        ...layout,
      }}
      widgetId={id}
      widgetType={type}
    >
      <InformationGridStripContainer data-category={category}>
        {items.map((item) => (
          <div className="information-item" key={item.label}>
            <Typography variant={TypographyVariants.SUBHEADING_BOLD}>
              {item.label}
            </Typography>
            <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
              {item.value}{' '}
            </Typography>
          </div>
        ))}
      </InformationGridStripContainer>
    </BaseWidgetComponent>
  )
}

export default InformationGridStrip
