import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'
import {IMediaWithHeaderSliderProps} from './MediaWithHeaderSlider.interface'
import MediaWithHeaderCard from './MediaWithHeaderCard/MediaWithHeaderCard'
import {MediaWithHeaderSliderContainer} from './MediaWithHeaderSlider.styles'
import {GenericSlider, Slider} from '@web-components'
import {useGenericActions} from '../../hooks'

function MediaWithHeaderSlider(props: IMediaWithHeaderSliderProps) {
  const {type, widgetData, header, layout, id} = props
  const {
    sliderConfig,
    items,
    titleColor,
    subtitleColor,
    checkIcon,
    slidesUiType = 'FLAT',
    cardBackground = '',
  } = widgetData || {}
  const {
    aspectRatio = 1,
    slidesToShow = 1,
    showPeek,
    peekVariant,
  } = sliderConfig || {}

  const {handleGenericActions} = useGenericActions()

  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithHeaderSliderContainer>
        <Slider
          slidesToShow={slidesToShow}
          aspectRatio={aspectRatio}
          showPeek={showPeek}
          peekVariant={peekVariant}
          itemCount={items.length}
          isMobileView={layout?.isSupportedOnlyForMobile}
        >
          {() =>
            items.map((item, index) => {
              const {
                header: itemHeader,
                media,
                topCalloutLabel,
                title,
                subtitle,
              } = item || {}
              return (
                <MediaWithHeaderCard
                  key={index}
                  header={itemHeader}
                  media={media}
                  topCalloutLabel={topCalloutLabel}
                  title={title}
                  subtitle={subtitle}
                  titleColor={titleColor}
                  subtitleColor={subtitleColor}
                  checkIcon={checkIcon}
                  uiType={slidesUiType || 'FLAT'}
                  cardBackground={cardBackground}
                  aspectRatio={aspectRatio}
                  handleGenericActions={handleGenericActions}
                />
              )
            })
          }
        </Slider>
      </MediaWithHeaderSliderContainer>
    </BaseWidgetComponent>
  )
}

export default MediaWithHeaderSlider
