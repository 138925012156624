import styled from 'styled-components'

export const MediaTextBlockCardWrapper = styled.div<{contentPosition: string}>`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  padding: var(--dls-size-24);
  @media (min-width: 768px) {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: ${({contentPosition}) =>
      contentPosition === 'right' ? 'row' : 'row-reverse'};
    justify-content: space-between;
  }

  .media-content {
    width: 100%;
    border-radius: var(--dls-size-16);

    @media (min-width: 768px) {
      width: 45%;
    }
  }

  .media {
    width: 100%;
    object-fit: cover;
    height: auto;
  }

  .text-content {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
    margin-bottom: var(--dls-size-16);
    @media (min-width: 768px) {
      width: 45%;
      display: flex;
      flex-direction: column;
    }
  }

  .heading {
    color: var(--dls-brand-primary-color-500);
  }

  .info {
    color: #1e1e1eb8;
  }
`
