import styled from 'styled-components'

export const MediaWithInfoPanelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  gap: 130px;
  width: 100%;

  .italic {
    font-style: italic;
  }

  .info-panel {
    background-color: var(--dls-brand-primary-color-500);
    border-radius: var(--dls-size-16);
    color: var(--dls-white-color);
    padding: 112px var(--dls-size-16) 42px;
    position: relative;
  }

  .block-icon-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .heading-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: var(--dls-size-8);
  }

  .heading-content ul {
    margin: 0;
    padding-left: var(--dls-size-16);
  }

  .detail-block {
    background: linear-gradient(
          90deg,
          var(--dls-brand-primary-color-500),
          var(--dls-brand-primary-color-500) 50%,
          var(--dls-brand-primary-color-500)
        )
        content-box,
      linear-gradient(
          125deg,
          #fff -20%,
          var(--dls-brand-primary-color-500) 20%,
          var(--dls-brand-primary-color-500) 80%,
          #fff 120%
        )
        border-box;
    border-radius: var(--dls-size-16);
    padding: 10px 10px;

    margin: var(--dls-size-32) 0;
  }

  .details-wrapper {
    border-radius: var(--dls-size-8);
    background: hsla(27, 85%, 97%, 0.1);
    padding: var(--dls-size-8) 6px;
  }

  .detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: var(--dls-size-8);
  }

  .icon-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--dls-size-16);
    row-gap: var(--dls-size-8);
    margin: var(--dls-size-8);
  }

  .icon-list-section {
    margin-bottom: var(--dls-size-16);
  }

  .list-element {
    display: flex;
    align-items: center;
    gap: var(--dls-size-8);
  }

  .mobile-tabs-container {
    padding: 25px 0;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 4;
  }

  .selected-tab {
    width: 100%;
    background-color: var(--dls-brand-primary-color-500);
    color: var(--dls-white-color);
    display: flex;
    justify-content: space-between;
    padding: var(--dls-size-8) var(--dls-size-32);
    border-radius: 50px;
    cursor: pointer;
  }

  .drop-down {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    color: var(--dls-brand-primary-color-500);
    background-color: white;
    padding: var(--dls-size-16) var(--dls-size-32);
    gap: var(--dls-size-32);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    border-radius: 12px;
    margin-top: 10px;
    position: absolute;
    top: var(--dls-size-64);
    left: 0;
  }

  .dropdown-tab {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    .block-icon-wrapper {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translate(-50%, -50%);
      max-width: 260px;
      width: 100%;
    }

    .tabs {
      width: 460px;
      gap: var(--dls-size-16);
      display: flex;
      flex-direction: column;
      align-items: end;
      color: var(--dls-divider-light-color);
    }

    .tab-element {
      cursor: pointer;
    }

    .active {
      color: var(--dls-black-color);
    }

    .info-panel {
      background-color: var(--dls-brand-primary-color-500);
      border-radius: var(--dls-size-16);
      color: var(--dls-white-color);
      padding: var(--dls-size-32) var(--dls-size-32) var(--dls-size-32) 144px;
      position: relative;
    }

    .heading-content {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: var(--dls-size-8);
    }

    .italic {
      font-style: italic;
    }
    .heading-content ul {
      margin: 0;
      padding-left: var(--dls-size-16);
    }

    .detail-block {
      background: linear-gradient(
            90deg,
            var(--dls-brand-primary-color-500),
            var(--dls-brand-primary-color-500) 50%,
            var(--dls-brand-primary-color-500)
          )
          content-box,
        linear-gradient(
            125deg,
            #fff -20%,
            var(--dls-brand-primary-color-500) 20%,
            var(--dls-brand-primary-color-500) 80%,
            #fff 120%
          )
          border-box;
      border-radius: var(--dls-size-16);
      padding: 10px 10px;
      margin: var(--dls-size-32) 0;
    }

    .details-wrapper {
      background: hsla(27, 85%, 97%, 0.1);
      padding: 8px 6px;
    }

    .detail {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: var(--dls-size-8);
    }

    .icon-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: var(--dls-size-48);
      row-gap: var(--dls-size-8);
      margin: var(--dls-size-8);
    }

    .list-element {
      display: flex;
      align-items: center;
      gap: var(--dls-size-8);
    }
  }
`
