import styled from 'styled-components'

export const StyledMobileMainItem = styled.div`
  padding: 0 var(--dls-size-16);
  padding-bottom: var(--dls-size-8);
  .tile-image {
    border-radius: var(--dls-size-16);
    overflow: hidden;
    cursor: zoom-in;
  }
`

export const StyledSliderItem = styled.div`
  padding-bottom: var(--dls-size-8);
  &[data-isActive='true'] {
    border-bottom: var(--dls-size-2) solid var(--dls-black-color);
  }
  .tile-image {
    cursor: pointer;
  }
`

export const StyledDesktopExpandedMediaContainer = styled.div`
  padding: 0 var(--dls-size-16);
  .tiles-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: var(--dls-size-16);
    row-gap: var(--dls-size-16);
    width: 100%;
  }
  .tile-image {
    object-fit: contain;
    width: 100%;
    height: auto;
    border-radius: var(--dls-size-16);
    overflow: hidden;
    cursor: zoom-in;
  }

  @media screen and (min-width: 991px) {
    .tiles-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`
