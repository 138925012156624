import {BaseWidget} from '@mosaic-wellness/fe-types'
import React, {useMemo} from 'react'

import {WidgetRendererContainer} from './WidgetRenderer.styles'
import {mapWidgetsFromWidgetData} from './mapWidgetsFromData'
import {WidgetType} from './Widget.Map'

interface WidgetRendererProps {
  widgets: BaseWidget[]
  CLIENT_SIDE_WIDGETS?: Record<WidgetType, React.ComponentType<any>>
  pageName?: string
}
function WidgetRenderer({
  widgets,
  CLIENT_SIDE_WIDGETS,
  pageName = '',
}: WidgetRendererProps) {
  const mappedWidgets = useMemo(() => {
    return mapWidgetsFromWidgetData({widgets, CLIENT_SIDE_WIDGETS})
  }, [CLIENT_SIDE_WIDGETS, widgets])

  return (
    <WidgetRendererContainer>
      {mappedWidgets.map((widget, index) => {
        const {Component, id} = widget
        const identifier = pageName ? `${pageName}-${id}` : id
        return (
          <Component key={id} {...widget} id={identifier} pageName={pageName} />
        )
      })}
    </WidgetRendererContainer>
  )
}

export default WidgetRenderer
