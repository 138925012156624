import styled from 'styled-components'

export const SocialReviewCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--dls-size-8);
  padding: var(--dls-size-8);
  border: var(--dls-size-1) solid var(--dls-divider-light-color);
  border-radius: var(--dls-size-8);
  transition: all 0.5s ease;

  .rating-container {
    display: flex;
    flex-direction: row;
  }
  .publish_detail_container {
    display: flex;
    flex-direction: column;

    .author_and_verified_icon_container {
      display: flex;
      flex-direction: row;
      gap: var(--dls-size-4);
      align-items: center;
    }

    .publish_text {
      color: var(--dls-secondary-text-colors);
    }
  }

  .publish_source_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--dls-size-1);
  }
`
