import styled from 'styled-components'

export const IconWithLabelSliderContainer = styled.div`
  width: 100%;

  .item-card {
    padding: var(--dls-size-16);
    border: 1px solid var(--dls-divider-light-color);
    background-color: var(--dls-white-color);
    border-radius: var(--dls-size-16);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 1;
    gap: var(--dls-size-8);
  }

  .label {
    margin-top: var(--dls-size-16);
  }
`
