import React from 'react'
import {MediaWithPillsWrapper} from './MediaWithPills.styles'
import {
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {IMediaWithPillProps, Pill} from './MediaWithPills.interface'

const MediaWithPills = (props: IMediaWithPillProps) => {
  const {data, aspectRatio} = props

  const {media, pills, title = '', subtitle = ''} = data || {}

  const {items} = pills || {}

  return (
    <MediaWithPillsWrapper>
      <div className="media-pills-image-wrapper">
        <Media
          media={media}
          desktopViewWidth={'THIRD'}
          mobileViewWidth={'THIRD'}
          aspectRatio={aspectRatio}
          customClassName="image"
        />
      </div>
      <div className="media-pll-content ">
        <Typography variant={TypographyVariants.HEADING_MEDIUM_REGULAR}>
          {title}
        </Typography>
        <Typography
          variant={TypographyVariants.SUBHEADING_LIGHT}
          customClassName="media-pill-subtitle"
        >
          {subtitle}
        </Typography>
      </div>
      <div className="media-pills-wrapper">
        {items.map((item: Pill, index: number) => (
          <div className="media-pill" key={index}>
            <Typography variant={TypographyVariants.X_SMALL}>
              {item.label}
            </Typography>
          </div>
        ))}
      </div>
    </MediaWithPillsWrapper>
  )
}

export default MediaWithPills
