import {IconWithLabelSliderContainer} from './MediaLabeledSlider.styles'
import {IMediaLabeledSliderProps} from './MediaLabeledSlider.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import {isEmpty} from 'lodash'
import {Media, Slider, Typography, TypographyVariants} from '@web-components'

function MediaLabeledSlider(props: IMediaLabeledSliderProps) {
  const {id = '', type = '', widgetData, header, layout} = props
  const {items = [], sliderConfig} = widgetData || {}
  const {
    slidesToShow = 1,
    aspectRatio,
    slidesToShowDesktop,
    showPeek,
    peekVariant,
    showArrows,
  } = sliderConfig || {}

  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent widgetId={id} widgetType={type} layout={layout}>
      <BaseWidgetComponent.Header header={header} />
      <IconWithLabelSliderContainer>
        <Slider
          aspectRatio={aspectRatio}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          showPeek={showPeek}
          peekVariant={peekVariant}
          showArrows={showArrows}
          itemCount={items.length}
        >
          {() =>
            items.map((item, index: number) => {
              const {media, label} = item || {}

              return (
                <div className="item-card" key={item.label}>
                  <div>
                    <Media
                      media={media}
                      desktopViewWidth={'THIRD'}
                      mobileViewWidth={'THIRD'}
                      aspectRatio={aspectRatio}
                    />
                  </div>

                  {label && (
                    <div className="text-container">
                      <Typography variant={TypographyVariants.BODY_BASE_LIGHT}>
                        <span dangerouslySetInnerHTML={{__html: label}} />
                      </Typography>
                    </div>
                  )}
                </div>
              )
            })
          }
        </Slider>
      </IconWithLabelSliderContainer>
    </BaseWidgetComponent>
  )
}

export default MediaLabeledSlider
