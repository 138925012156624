import styled from 'styled-components'

export const MediaWithPillsSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--dls-size-24);
  margin-bottom: var(--dls-size-16);
  position: relative;

  @media (min-width: 768px) {
    margin-top: var(--dls-size-64);
  }

  .slider {
    margin-top: var(--dls-size-40);
    margin-bottom: var(--dls-size-24);
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }

  .bottom-cta {
    width: 62%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
`
