import styled from 'styled-components'

export const BrickSectionWrapper = styled.div<{
  textColor: string
  headingColor: string
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  color: ${({textColor}) => textColor};
  max-width: 1400px !important;

  .brick-container {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: var(--dls-size-24);
    padding: var(--dls-size-24);
    flex-direction: column;
    margin: 0;
    width: 100%;

    .title {
      margin-bottom: 0px !important;
    }

    .content {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: start;
      margin: 0;
      width: 100%;
    }
  }

  .content {
    width: 50%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    padding: 50px 50px 50px 0;

    .title {
      margin-bottom: 5px;
    }

    .tick-icon {
      height: var(--dls-size-32);
      width: var(--dls-size-32);
    }

    .item {
      display: flex;
      align-items: center;
      gap: var(--dls-size-8);
    }

    i {
      color: var(--dls-brand-primary-color-500);
    }

    .items-wrapper {
      margin-bottom: var(--dls-size-8);
    }
  }

  .media {
    width: 100%;
    border-radius: var(--dls-size-24);
    overflow: hidden;
    display: inline-block;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .media-content {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .description-text {
    color: var(--dls-black-color);
  }
  @media screen and (min-width: 768px) {
    .brick-container {
      padding: var(--dls-size-40) 0;
      flex-direction: row;
    }
    .content {
      width: 50%;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 10px;
      padding: 50px 50px 50px 0;

      .title {
        margin-bottom: 5px;
      }

      .tick-icon {
        height: 20px;
        width: 20px;
      }

      .item {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .section-heading {
        color: ${({headingColor}) => headingColor};
        width: 75%;
      }

      .section-heading i {
        color: var(--dls-brand-primary-color-500);
      }

      .items-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: var(--dls-size-8);
      }
    }
  }
`
