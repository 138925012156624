import React, {useCallback, useEffect, useState} from 'react'
import {MediaWithProductOverlayCardContainer} from './MediaWithProductOverlayCard.styles'
import {
  Button,
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {MediaWithProductOverlayCardProps} from '../MediaWithProductOverlay.interface'

function MediaWithProductOverlayCard({
  product,
  aspectRatio,
  videoMutedIcon = '',
  videoUnMutedIcon = '',
  isActive,
  setActiveVideo,
  handleAction,
}: MediaWithProductOverlayCardProps) {
  const {baseMedia, overlayMedia, name = '', cta} = product || {}

  const {label, variant, size, actions = []} = cta || {}

  const [videoMuted, setVideoMuted] = useState(true)

  // When the active video changes, ensure that this video is muted if it's not active
  useEffect(() => {
    setVideoMuted(!isActive)
  }, [isActive])

  const handleOnClick = useCallback(() => {
    const defaultCardAction = [{actionName: 'OPEN_PDP', params: product}]
    handleAction(actions || defaultCardAction)
  }, [actions, handleAction, product])

  const toggleMute = () => {
    if (!isActive) {
      setActiveVideo() // Set this video as active in parent component
    }
    setVideoMuted((muted) => !muted)
  }

  return (
    <MediaWithProductOverlayCardContainer>
      {baseMedia && (
        <div className="base-media-container">
          <div className="media-overlay" />
          <Media
            media={baseMedia}
            desktopViewWidth={'THIRD'}
            mobileViewWidth={'THIRD'}
            aspectRatio={aspectRatio}
            customClassName="base-media"
          />
          {baseMedia.mediaType === 'video' ? (
            <div onClick={toggleMute} className="video-mute-button">
              {videoMuted ? (
                <OptimizedImage
                  source={videoMutedIcon}
                  aspectWidth={24}
                  aspectHeight={24}
                  desktopViewWidth="TINY"
                  mobileViewWidth="TINY"
                  altText="oops"
                  loading="lazy"
                />
              ) : (
                <OptimizedImage
                  source={videoUnMutedIcon}
                  aspectWidth={24}
                  aspectHeight={24}
                  desktopViewWidth="TINY"
                  mobileViewWidth="TINY"
                  altText="oops"
                  loading="lazy"
                />
              )}
            </div>
          ) : null}
        </div>
      )}
      {overlayMedia ? (
        <div className="bottom-container">
          <div className="product-image-container">
            <Media
              media={overlayMedia}
              desktopViewWidth={'THIRD'}
              mobileViewWidth={'THIRD'}
              aspectRatio={aspectRatio}
            />
          </div>

          <div className="text-container">
            <Typography variant={TypographyVariants.TAG_REGULAR}>
              {name}
            </Typography>
          </div>
          <Button
            label={label}
            variant={variant}
            size={size}
            onClick={handleOnClick}
          />
        </div>
      ) : null}
    </MediaWithProductOverlayCardContainer>
  )
}

export default MediaWithProductOverlayCard
