import React, {useCallback} from 'react'
import {
  TypographyVariants,
  Typography,
  ChevronRight,
  Media,
} from '@web-components'
import {DesktopCategoryPaletteContainer} from './CategoryCard.styles'
import {CategoryCardProps} from './CategoryCard.interface'

function CategoryCard(props: CategoryCardProps) {
  const {cardData, handleAction, aspectRatio = 1} = props || {}

  const {
    media,
    categoryName,
    description,
    slug = '#',
    backgroundColor,
    actions,
  } = cardData || {}

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      handleAction(actions, categoryName)
    },
    [handleAction, actions, categoryName]
  )

  return (
    <DesktopCategoryPaletteContainer
      href={slug}
      onClick={handleClick}
      backgroundColor={backgroundColor}
    >
      <Media
        media={media}
        desktopViewWidth={'THIRD'}
        mobileViewWidth={'THIRD'}
        aspectRatio={aspectRatio}
        customClassName="media-class"
      />
      <div className="content-container">
        <div className="content-top">
          <Typography
            variant={TypographyVariants.HEADING_SMALL_BOLD}
            customClassName="categoryName"
          >
            {categoryName}
          </Typography>
          <ChevronRight />
        </div>
        <div className="content-bottom">
          <Typography
            variant={TypographyVariants.SUBHEADING_REGULAR}
            customClassName="description"
          >
            {description}
          </Typography>
        </div>
      </div>
    </DesktopCategoryPaletteContainer>
  )
}

export default CategoryCard
