import styled from 'styled-components'

export const MediaTextGridCardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--dls-size-16);
  @media (min-width: 768px) {
    width: 80%;
    margin: var(--dls-size-64) auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .media-content {
    width: 55%;
    border-radius: var(--dls-size-16);

    @media (min-width: 768px) {
      width: 45%;
    }
  }

  .media {
    width: 100%;
    object-fit: cover;
    height: auto;
  }

  .text-content {
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-8);
    margin: var(--dls-size-16) auto;
    @media (min-width: 768px) {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }

  .heading-content {
    display: flex;
    flex-direction: column;
  }

  .image-text-card {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .text-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--dls-size-16) 0;
    border-top: 1px solid #dfdad5;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--dls-size-32);
    margin-top: var(--dls-size-24);
  }
`
