import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'

import {HorizontalProductCardWrapper} from './HorizontalProductCardGrid.styles'
import noop from 'lodash/noop'
import {useCallback} from 'react'
import {HorizontalProductCard} from '@web-components'
import {HorizontalProductCardGridProps} from './HorizontalProductCardGrid.interface'
import {Product} from 'src/mono/web-components/components/molecules/HorizontalProductCard/HorizontalProductCard.types'

const ProductListing = (
  props: BaseWidgetProps<HorizontalProductCardGridProps>
) => {
  const {layout, id, type, header, widgetData} = props
  const {
    products = [],
    enableQuantityIndicator = false,
    shouldOpenMiniPdp = false,
    miniPdpCta = {},
    productImageAspectRatio = 1,
    isCartUpdating,
    itemBeingAddedToCart,
    cartItems,
    handleCardClick = noop,
    handleCtaClick = noop,
    handleUpdateCartItem = noop,
  } = widgetData || {}

  const handleCardClickCallback = useCallback(
    (product: Product) => {
      handleCardClick(product, shouldOpenMiniPdp, miniPdpCta)
    },
    [handleCardClick, shouldOpenMiniPdp, miniPdpCta]
  )
  if (!products) return null
  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <HorizontalProductCardWrapper>
        <div className="product-card-list">
          {products?.map((product: Product) => {
            return (
              <HorizontalProductCard
                key={product?.urlKey}
                product={product}
                handleCtaClick={handleCtaClick}
                handleCardClick={handleCardClickCallback}
                handleUpdateCartItem={handleUpdateCartItem}
                enableQuantityIndicator={enableQuantityIndicator}
                isCartUpdating={isCartUpdating}
                itemBeingAddedToCart={itemBeingAddedToCart}
                cartItems={cartItems}
                productImageAspectRatio={productImageAspectRatio}
              />
            )
          })}
        </div>
      </HorizontalProductCardWrapper>
    </BaseWidgetComponent>
  )
}

export default ProductListing
