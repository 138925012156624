import isEmpty from 'lodash/isEmpty'
import {BaseWidgetComponent} from '../BaseWidget'

import {useGenericActions} from '../../hooks'
import {Slider, useDeviceTypeContext} from '@web-components'

import InfoCard from './InfoCard'
import {StyledInfoCardsSlider} from './InfoCards.styles'
import {InfoCardsSliderProps} from './InfoCards.interface'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {useCallback} from 'react'

const InfoCards = ({
  widgetData,
  layout,
  header,
  type,
  id,
}: BaseWidgetProps<InfoCardsSliderProps>) => {
  const {items = [], sliderConfig} = widgetData || {}
  const {
    slidesToShow,
    slidesToShowDesktop,
    aspectRatio = 1,
    showPeek,
    peekVariant,
  } = sliderConfig || {}
  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (actions: GenericAction[], label: string) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id]
  )
  const {isMobile} = useDeviceTypeContext()

  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <StyledInfoCardsSlider>
        <Slider
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          aspectRatio={aspectRatio}
          showArrows={!isMobile && slidesToShowDesktop !== items.length}
          itemCount={items.length}
          showPeek={showPeek}
          peekVariant={peekVariant}
          isMobileView={layout?.isSupportedOnlyForMobile}
        >
          {() => (
            <>
              {items.map((item) => {
                return (
                  <InfoCard
                    key={item.slug}
                    data={item}
                    aspectRatio={aspectRatio}
                    handleGenericActions={handleClick}
                  />
                )
              })}
            </>
          )}
        </Slider>
      </StyledInfoCardsSlider>
    </BaseWidgetComponent>
  )
}

export default InfoCards
