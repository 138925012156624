import {MediaWithHeaderCardContainer} from './MediaWithHeaderCard.styles'
import {
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useMemo} from 'react'
import noop from 'lodash/noop'
import {IMediaWithHeaderCardProps} from './MediaWithHeaderCard.interface'

function MediaWithHeaderCard(props: IMediaWithHeaderCardProps) {
  const {
    header = '',
    media,
    uiType = '',
    topCalloutLabel = '',
    cardBackground = '',
    checkIcon = '',
    aspectRatio = 1,
    title,
    subtitle,
    titleColor,
    subtitleColor,
    actions,
    handleGenericActions = noop,
  } = props

  const showHeader = useMemo(() => {
    return !!header || !!title || !!subtitle
  }, [header, subtitle, title])

  if (!media) {
    return null
  }

  return (
    <MediaWithHeaderCardContainer
      data-uiType={uiType}
      style={{background: cardBackground}}
      onClick={() => {
        if (actions) {
          handleGenericActions(actions)
        }
      }}
    >
      {!!topCalloutLabel && (
        <div className="top-callout">
          <Typography variant={TypographyVariants.X_SMALL}>
            {topCalloutLabel}
          </Typography>
        </div>
      )}
      {!!showHeader && (
        <div className="header-container">
          <div className="header-text">
            {!!header && (
              <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                <span dangerouslySetInnerHTML={{__html: header}} />
              </Typography>
            )}

            {!!title && (
              <Typography
                style={{color: titleColor}}
                variant={TypographyVariants.BODY_BASE_REGULAR}
              >
                {title}
              </Typography>
            )}

            {!!subtitle && (
              <Typography
                style={{color: subtitleColor}}
                variant={TypographyVariants.BODY_BASE_BOLD}
              >
                {subtitle}
              </Typography>
            )}
          </div>

          {!!checkIcon && (
            <div className="icon">
              <OptimizedImage
                source={checkIcon}
                aspectHeight="20"
                aspectWidth="20"
                customClassName="icon-image"
              />
            </div>
          )}
        </div>
      )}
      <div className="card-image-container">
        <Media
          media={media}
          desktopViewWidth={'THIRD'}
          mobileViewWidth={'THIRD'}
          aspectRatio={aspectRatio}
        />
      </div>
    </MediaWithHeaderCardContainer>
  )
}

export default MediaWithHeaderCard
