import styled from 'styled-components'

export const MediaWithPillsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  gap: var(--dls-size-16);

  .media-pills-image {
    width: 100%;
  }

  .media-pills-wrapper {
    display: flex;
    gap: var(--dls-size-8);
    flex-wrap: wrap;
  }

  .media-pill {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--dls-brand-primary-color-500);
    border-radius: var(--dls-size-16);
    padding: 4px var(--dls-size-8);
    color: var(--dls-brand-primary-color-500);
  }

  .media-pll-content {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 4px;
    color: var(--dls-brand-primary-color-500);
  }

  .media-pill-subtitle {
    color: var(--dls-black-color);
  }
  @media (min-width: 768px) {
    width: 75%;
  }
`
