import styled from 'styled-components'

export const MediaBackgroundWithCtaWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .banner {
    width: 100%;
    object-fit: cover;
    border-radius: 0 0 50px 50px;
  }

  .banner-content {
    position: absolute;
    top: 27%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: var(--dls-size-24);
    text-align: center;
    width: 100%;
    i {
      color: var(--dls-brand-primary-color-500);
    }
  }

  .banner-btn-wrapper {
    margin: 0 auto;
  }

  @media screen and (min-width: 786px) {
    .banner {
      height: 95vh;
      width: 94%;
    }
  }
`
