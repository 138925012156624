import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import React, {useCallback, useEffect, useMemo} from 'react'
import CatalogCardSlider from '../CatalogCards/CatalogCards'
import {useRecentlyViewedProducts} from 'src/hooks/product/useRecentlyViewedProducts/useRecentlyViewedProducts'
import {usePathname} from 'next/navigation'

const RecentlyViewedCatalogCards = (props: BaseWidgetProps<any>) => {
  const {widgetData, id, type, layout, header} = props

  const pathname = usePathname()
  const productSlug = useMemo(() => {
    return pathname.split('/').pop() as string
  }, [pathname])

  const [{recentlyViewedProducts}, {fetchRecentlyViewedProducts}] =
    useRecentlyViewedProducts()

  const fetchRecentlyViewedData = useCallback(async () => {
    await fetchRecentlyViewedProducts(productSlug)
  }, [productSlug, fetchRecentlyViewedProducts])

  useEffect(() => {
    fetchRecentlyViewedData()
  }, [fetchRecentlyViewedData])

  return (
    <CatalogCardSlider
      widgetData={{...widgetData, products: recentlyViewedProducts || []}}
      layout={layout}
      type={type}
      id={id}
      header={header}
    />
  )
}

export default RecentlyViewedCatalogCards
