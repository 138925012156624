import styled from 'styled-components'

export const StyledInfoCardsSlider = styled.div`
  a {
    color: inherit;
    text-decoration: none;
  }
  .arrow-right-container {
    path {
      stroke: var(--dls-brand-primary-color-500) !important;
    }
  }
  .arrow-left-container {
    path {
      stroke: var(--dls-brand-primary-color-500) !important;
    }
  }
`

export const StyledBlogCard = styled.a`
  border-radius: var(--dls-size-16);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .usp-image {
    width: 100%;
    height: auto;
    margin-bottom: var(--dls-size-8);
    object-fit: cover;
  }

  .expectation-title {
    font-style: normal;
    padding: 0 var(--dls-size-16);
    margin: var(--dls-size-16) 0 var(--dls-size-8);
  }

  .expectation-content {
    margin: 0;
    font-style: normal;
    letter-spacing: 0.5px;
    padding: 0 var(--dls-size-16) var(--dls-size-16);
    color: var(--dls-secondary-text-colors);
    flex-grow: 1;
  }

  .learn-more {
    border-radius: 0;
  }

  @media screen and (min-width: 768px) {
    .expectation-content {
      min-height: 220px;
    }
    .learn-more {
      padding: var(--dls-size-16);
    }
  }
`
