import React from 'react'
import MediaWithReadMoreCard from './MediaWithReadMoreCard/MediaWithReadMoreCard'
import {MediaWithReadMoreWrapper} from './MediaWithReadMore.styles'
import {BaseWidget, BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {MediaWithReadMoreCardData} from './MediaWithReadMoreCard/MediaWithReadMoreCard.interface'
import {MediaWithReadMoreProps} from './MediaWithReadMore.interface'

const MediaWithReadMore = (props: BaseWidgetProps<MediaWithReadMoreProps>) => {
  const {widgetData, type, layout, id, header} = props

  const {items = []} = widgetData || {}
  return (
    <BaseWidgetComponent widgetType={type} layout={layout} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaWithReadMoreWrapper>
        <div className="card-container">
          {items.map((item: MediaWithReadMoreCardData, index: number) => (
            <MediaWithReadMoreCard
              cardData={item}
              key={`media-read-more-${index}`}
            />
          ))}
        </div>
      </MediaWithReadMoreWrapper>
    </BaseWidgetComponent>
  )
}

export default MediaWithReadMore
