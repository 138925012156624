import React, {useCallback} from 'react'
import {BaseWidgetProps} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {isEmpty} from 'lodash'
import {MediaCarouselContainer} from './MediaCarousel.styles'
import {Carousel, MediaWithCta} from '@web-components'
import {useGenericActions} from '../../hooks'
import {MediaCarouselProps} from './MediaCarousel.interface'

function MediaCarousel(props: BaseWidgetProps<MediaCarouselProps>) {
  const {widgetData, id, header, layout, type} = props || {}
  const {items = [], carouselConfig} = widgetData || {}

  const {
    aspectRatio = 1,
    showDots,
    showArrows,
    slidesToShow,
    slidesToShowDesktop,
    autoSlide,
    autoSlideInterval,
    infinite,
    slidesToScroll,
    arrowPosition = 'inside',
  } = carouselConfig || {}

  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (actions: GenericAction[], label?: string) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id]
  )

  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaCarouselContainer>
        <Carousel
          showDots={showDots}
          showArrows={showArrows}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          autoSlide={autoSlide}
          autoSlideInterval={autoSlideInterval}
          infinite={infinite}
          slidesToScroll={slidesToScroll}
          arrowPosition={arrowPosition}
        >
          {items.map((item, index: number) => {
            return (
              <MediaWithCta
                key={index}
                data={item}
                aspectRatio={aspectRatio}
                onClickActions={handleClick}
                index={index}
              />
            )
          })}
        </Carousel>
      </MediaCarouselContainer>
    </BaseWidgetComponent>
  )
}

export default MediaCarousel
