import React from 'react'
import {MediaTextGridCardWrapper} from './MediaWithTextGrid.styles'
import {
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
  useDeviceTypeContext,
} from '@web-components'
import {ICardData} from './MediaWithTextGrid.interface'

const MediaWithTextGrid = (props: ICardData) => {
  const {cardData} = props
  const {media, content} = cardData
  const {heading, info, items} = content || {}
  const {isMobile = false} = useDeviceTypeContext()

  return (
    <MediaTextGridCardWrapper>
      <div className="media-content">
        <Media
          media={media}
          desktopViewWidth="THIRD"
          mobileViewWidth="THIRD"
          customClassName="media"
        />
      </div>

      <div className="text-content">
        <div className="heading-content">
          <Typography
            variant={TypographyVariants.HEADING_MEDIUM_REGULAR}
            customClassName="heading"
          >
            {heading}
          </Typography>
          <Typography
            variant={TypographyVariants.TAG_REGULAR}
            customClassName="info"
          >
            {info}
          </Typography>
        </div>

        <div className="grid">
          {items.map((item, index) => (
            <div className="image-text-card" key={`image-text-card-${index}`}>
              <div className="image-section">
                <OptimizedImage
                  source={item.image}
                  desktopViewWidth="SMALL"
                  mobileViewWidth="SMALL"
                  aspectHeight={isMobile ? 30 : 40}
                  aspectWidth={isMobile ? 30 : 40}
                />
              </div>
              <div className="text-section">
                <Typography variant={TypographyVariants.SUBHEADING_REGULAR}>
                  {item.title}
                </Typography>
                <Typography variant={TypographyVariants.TAG_REGULAR}>
                  {item.description}
                </Typography>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MediaTextGridCardWrapper>
  )
}

export default MediaWithTextGrid
