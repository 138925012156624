import React, {useCallback} from 'react'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {isEmpty} from 'lodash'
import {MediaSliderContainer} from './MediaSlider.styles'
import {MediaWithCta, Slider} from '@web-components'
import {MediaSliderProps} from './MediaSlider.interface'
import {useGenericActions} from '../../hooks'
import {BaseWidgetComponent} from '../BaseWidget'

function MediaSlider(props: BaseWidgetProps<MediaSliderProps>) {
  const {widgetData, id, header, layout, type} = props || {}
  const {items = [], sliderConfig, showBorder = true} = widgetData || {}

  const {
    aspectRatio = 1,
    slidesToShow,
    showPeek,
    peekVariant,
    slidesToShowDesktop,
    showArrows,
  } = sliderConfig || {}

  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (actions: GenericAction[], label?: string) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id]
  )

  if (isEmpty(items)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaSliderContainer data-show-border={showBorder}>
        <Slider
          aspectRatio={aspectRatio}
          slidesToShow={slidesToShow}
          slidesToShowDesktop={slidesToShowDesktop}
          showPeek={showPeek}
          peekVariant={peekVariant}
          showArrows={showArrows}
          itemCount={items.length}
          isMobileView={layout?.isSupportedOnlyForMobile}
        >
          {() =>
            items.map((item, index: number) => {
              return (
                <MediaWithCta
                  key={index}
                  data={item}
                  aspectRatio={aspectRatio}
                  onClickActions={handleClick}
                  index={index}
                  customClass="custom-media-slider"
                />
              )
            })
          }
        </Slider>
      </MediaSliderContainer>
    </BaseWidgetComponent>
  )
}

export default MediaSlider
