import React, {useCallback, useEffect, useRef, useState} from 'react'
import {ExpandedMediaProps} from './ExpandedMedia.interface'

const useExpandedMedia = (props: ExpandedMediaProps) => {
  const {items = []} = props || {}

  const [activeSlide, setActiveSlide] = useState<number>(0)
  const [startX, setStartX] = useState<number>(0)
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false)
  const sliderRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!sliderRef.current) return

    const handleTouchStart = (event: TouchEvent) => {
      setStartX(event.touches[0].clientX)
    }

    const handleTouchEnd = (event: TouchEvent) => {
      const endX = event.changedTouches[0].clientX
      const deltaX = endX - startX

      if (Math.abs(deltaX) > 10) {
        // Minimum swipe distance threshold
        const lastIndex = items.length - 1
        setActiveSlide((prev) => {
          if (deltaX < 0) {
            return prev === lastIndex ? 0 : prev + 1
          } else {
            return prev === 0 ? lastIndex : prev - 1
          }
        })
      }
    }

    const currentSlider = sliderRef.current
    currentSlider.addEventListener('touchstart', handleTouchStart)
    currentSlider.addEventListener('touchend', handleTouchEnd)

    return () => {
      currentSlider.removeEventListener('touchstart', handleTouchStart)
      currentSlider.removeEventListener('touchend', handleTouchEnd)
    }
  }, [startX])

  const handleSliderItemClick = useCallback(
    (index: number) => () => {
      setActiveSlide(index)
    },
    []
  )

  const previewImage = () => {
    setShowPreviewModal(true)
  }

  const closePreviewModal = () => {
    setShowPreviewModal(false)
  }

  const handleDesktopItemClick = useCallback(
    (index: number) => () => {
      setActiveSlide(index)
      previewImage()
    },
    []
  )

  return {
    sliderRef,
    activeSlide,
    handleSliderItemClick,
    previewImage,
    showPreviewModal,
    closePreviewModal,
    handleDesktopItemClick,
  }
}

export default useExpandedMedia
