import isEmpty from 'lodash/isEmpty'
import {IReviewsListProps} from './ReviewsList.interface'
import {ReviewsListContainer} from './ReviewsList.styles'
import ReviewCard from '../ReviewCard/ReviewCard'

function ReviewsList(props: IReviewsListProps) {
  const {reviews} = props

  if (isEmpty(reviews)) {
    return null
  }

  return (
    <ReviewsListContainer>
      {reviews.map((review, index) => (
        <ReviewCard key={index} review={review} />
      ))}
    </ReviewsListContainer>
  )
}

export default ReviewsList
