import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import React, {useCallback} from 'react'
import {BaseWidgetComponent} from '../BaseWidget'
import {BrickSectionWrapper} from './BrickSection.styles'
import {
  OptimizedImage,
  Typography,
  TypographyVariants,
  CtaWithAnimation,
  Media,
} from '@web-components'
import {useGenericActions} from '../../hooks'
import {BrickSectionProps} from './BrickSection.interface'

const brickVariant = {
  PRIMARY: {
    backGroundColor: 'var(--dls-brand-primary-color-500)',
    textColor: 'var(--dls-white-color)',
    ctaVariant: 'TERTIARY',
    headingColor: 'var(--dls-white-color)',
  },
  SECONDARY: {
    backGroundColor: 'transparent',
    textColor: 'var(--dls-brand-primary-color-500)',
    ctaVariant: 'PRIMARY',
    headingColor: 'var(--dls-black-color)',
  },
}

const BrickSection = (props: BaseWidgetProps<BrickSectionProps>) => {
  const {id, layout, widgetData, type} = props
  const {
    title = '',
    media,
    aspectRatio = 1,
    cta,
    heading = '',
    items = [],
    variant = 'PRIMARY',
    description = '',
    textField = 'list',
  } = widgetData || {}

  const styles = brickVariant[variant] || brickVariant.PRIMARY

  const {label, variant: ctaVariant, actions} = cta || {}

  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (actions: GenericAction[]) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id, label]
  )

  const renderTextContent = useCallback(
    (type: string) => {
      switch (type) {
        case 'list':
          return items.map((item: any, index: number) => (
            <div key={index} className="item">
              <OptimizedImage
                source={item.icon?.source}
                altText={item.icon?.altText}
                customClassName="tick-icon"
              />
              <Typography
                variant={TypographyVariants.BODY_BASE_REGULAR}
                customClassName="item-text"
              >
                {item.label}
              </Typography>
            </div>
          ))

        case 'text':
          return (
            <Typography
              variant={TypographyVariants.BODY_BASE_REGULAR}
              customClassName="description-text"
            >
              {description}
            </Typography>
          )

        default:
          return null
      }
    },
    [description, items]
  )

  return (
    <BaseWidgetComponent widgetType={type} widgetId={id} layout={layout}>
      <BrickSectionWrapper
        textColor={styles.textColor}
        headingColor={styles.headingColor}
      >
        <div className="brick-container">
          <div className="content">
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="title"
            >
              {title}
            </Typography>
            <Typography
              variant={TypographyVariants.HEADING_2XLARGE_REGULAR}
              customClassName="section-heading"
            >
              <span dangerouslySetInnerHTML={{__html: heading}} />
            </Typography>
            <div className="items-wrapper">{renderTextContent(textField)}</div>
            <CtaWithAnimation
              onClick={() => handleClick(actions)}
              variant={ctaVariant}
            >
              {label}
            </CtaWithAnimation>
          </div>
          <div className="media">
            <Media
              media={media}
              customClassName="media-content"
              aspectRatio={aspectRatio}
              mobileViewWidth="THIRD"
              desktopViewWidth="THIRD"
            />
          </div>
        </div>
      </BrickSectionWrapper>
    </BaseWidgetComponent>
  )
}

export default BrickSection
