import React, {useCallback} from 'react'
import {DesktopCategoryPaletteWrapper} from './CategoryCardGrid.styles'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {useGenericActions} from '../../hooks'
import CategoryCard from './CategoryCard/CategoryCard'
import {CategoryCardGridProps} from './CategoryCardGrid.interface'

function CategoryCardGrid(props: BaseWidgetProps<CategoryCardGridProps>) {
  const {type, widgetData, header, layout, id} = props || {}
  const {items = [], aspectRatio = 1} = widgetData || {}

  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (actions: GenericAction[], label: string) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id]
  )

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <DesktopCategoryPaletteWrapper noOfColumns={items.length}>
        {items.map((data, index) => (
          <CategoryCard
            cardData={data}
            aspectRatio={aspectRatio}
            handleAction={handleClick}
            key={index}
          />
        ))}
      </DesktopCategoryPaletteWrapper>
    </BaseWidgetComponent>
  )
}

export default CategoryCardGrid
