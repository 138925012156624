import styled from 'styled-components'

export const MediaWithTextGridWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .tabs-container {
    display: flex;
    background-color: var(--dls-brand-primary-color-500);
    color: var(--dls-white-color);
    width: max-content;
    gap: var(--dls-size-32);
    padding: 4px;
    margin: 20px auto;
    border-radius: 50px;
  }

  .tab {
    cursor: pointer;
    padding: 8px var(--dls-size-32);
    border-radius: 50px;
  }

  .active {
    cursor: pointer;
    background-color: white;
    color: var(--dls-brand-primary-color-500);
    padding: 8px var(--dls-size-32);
    border-radius: 50px;
  }

  .mobile-tabs-container {
    padding: 25px var(--dls-size-16);
    width: 100%;
    margin: auto;
  }

  .selected-tab {
    width: 100%;
    background-color: var(--dls-brand-primary-color-500);
    color: var(--dls-white-color);
    display: flex;
    justify-content: space-between;
    padding: 8px var(--dls-size-32);
    border-radius: 50px;
    cursor: pointer;
  }

  .drop-down {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    color: var(--dls-brand-primary-color-500);
    background-color: var(--dls-background-color-500);
    padding: var(--dls-size-16) var(--dls-size-32);
    gap: var(--dls-size-32);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    border-radius: 12px;
    margin-top: 10px;
  }

  .dropdown-tab {
    width: 100%;
  }
`
