import styled from 'styled-components'

export const MediaWithProductOverlayCardContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .base-media-container {
    position: relative;
  }

  .media-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--dls-size-8);
  }
  .base-media {
    border-radius: var(--dls-size-8);
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  .video-mute-button {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .bottom-container {
    transform: translateY(-45px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .product-image-container {
    background: var(--dls-white-color);
    border-radius: var(--dls-size-8);
    padding: var(--dls-size-4);
    box-shadow: 0px 1px 3px 0px #0000000d, 0px 5px 5px 0px #0000000a,
      0px 11px 7px 0px #00000008, 0px 20px 8px 0px #00000003,
      0px 31px 9px 0px #00000000;
    width: 80px;
    height: 80px;
  }
  .text-container {
    margin-top: var(--dls-size-8);
    width: 100px;
    text-align: center;
  }
`
