import isEmpty from 'lodash/isEmpty'
import {StyledBlogCard} from './InfoCards.styles'
import {
  Button,
  Media,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '@web-components'
import {useCallback} from 'react'
import {InfoCardProps} from './InfoCards.interface'

const InfoCard = (props: InfoCardProps) => {
  const {data, aspectRatio = 2, handleGenericActions} = props
  const {title, content, media, cta, slug} = data || {}

  const {
    label = 'Learn More',
    variant = 'PRIMARY',
    size = 'SMALL',
    actions = [],
  } = cta || {}

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      handleGenericActions(actions, title)
    },
    [actions, handleGenericActions, title]
  )

  if (isEmpty(data)) {
    return null
  }

  return (
    <StyledBlogCard href={slug} onClick={handleClick}>
      <Media
        media={media}
        desktopViewWidth={'THIRD'}
        mobileViewWidth={'THIRD'}
        aspectRatio={aspectRatio}
        customClassName="usp-image"
      />
      <Typography
        variant={TypographyVariants.SUBHEADING_BOLD}
        customClassName="expectation-title"
      >
        {title}
      </Typography>

      <Typography
        variant={TypographyVariants.BODY_BASE_REGULAR}
        customClassName="expectation-content"
      >
        {content}
      </Typography>
      <Button
        label={label}
        variant={variant}
        size={size}
        fullWidth={true}
        onClick={() => handleClick}
        customClassName="learn-more"
      />
    </StyledBlogCard>
  )
}

export default InfoCard
