import React, {useCallback} from 'react'
import {BaseWidgetProps, GenericAction} from '@mosaic-wellness/fe-types'
import {BaseWidgetComponent} from '../BaseWidget'
import {isEmpty} from 'lodash'
import {MediaBannerContainer} from './MediaBanner.styles'
import {MediaWithCta} from '@web-components'
import {useGenericActions} from '../../hooks'
import {IMediaBanner} from './MediaBanner.interface'

function MediaBanner(props: BaseWidgetProps<IMediaBanner>) {
  const {widgetData, id, header, layout, type} = props || {}
  const {media, slug, cta, aspectRatio = 1} = widgetData || {}

  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (actions: GenericAction[], label?: string) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id]
  )

  if (isEmpty(media)) {
    return null
  }

  return (
    <BaseWidgetComponent layout={layout} widgetType={type} widgetId={id}>
      <BaseWidgetComponent.Header header={header} />
      <MediaBannerContainer>
        <MediaWithCta
          data={{media, cta, slug}}
          aspectRatio={aspectRatio}
          onClickActions={handleClick}
        />
      </MediaBannerContainer>
    </BaseWidgetComponent>
  )
}

export default MediaBanner
