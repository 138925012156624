import React, {useCallback} from 'react'
import {CtaButtonContainer} from './CallToActions.styles'
import {ICtaButtonProps} from './CallToActions.interface'
import {BaseWidgetComponent} from '../BaseWidget'
import isEmpty from 'lodash/isEmpty'
import {Button, CtaWithAnimation} from '@web-components'
import {useGenericActions} from '../../hooks'
import {GenericAction} from '@mosaic-wellness/fe-types'
import BuyOptionsWithQty from './BuyOptionsWithQty/BuyOptionsWithQty'

function CallToActions(props: ICtaButtonProps) {
  const {id, type, widgetData, layout} = props
  const {
    items = [],
    isSticky = false,
    isStickyFooter = false,
    isArrowAnimatedButton = false,
    stickyFooterVariant = 'PRIMARY',
  } = widgetData || {}
  const {handleGenericActions} = useGenericActions()

  const handleClick = useCallback(
    (actions: GenericAction[], label: string) => {
      const {params} = actions[0]

      const analyticsEvent = {
        actionName: 'TRIGGER_EVENTS_FOR_WEB',
        params: {
          event: 'CTA CLICKED',
          eventData: {
            source: id,
            label,
            ...params,
          },
        },
      }

      handleGenericActions([...actions, analyticsEvent])
    },
    [handleGenericActions, id]
  )

  if (isEmpty(items)) {
    return null
  }

  if (isStickyFooter && stickyFooterVariant === 'SECONDARY') {
    return (
      <BuyOptionsWithQty
        id={id}
        layout={layout}
        type={type}
        widgetData={widgetData}
      />
    )
  }
  return (
    <BaseWidgetComponent layout={layout} widgetId={id} widgetType={type}>
      <CtaButtonContainer
        data-issticky={isSticky}
        data-isstickyfooter={isStickyFooter}
      >
        {items.map((item) => {
          const {
            actions,
            label,
            variant = 'SECONDARY',
            size = 'LARGE',
            fullWidth = false,
            customType = '',
            loading = false,
            disabled = false,
          } = item || {}
          if (!label) return null
          if (isArrowAnimatedButton) {
            return (
              <CtaWithAnimation
                key={label}
                onClick={() => {
                  handleClick(actions)
                }}
                variant={variant}
              >
                {label}
              </CtaWithAnimation>
            )
          } else {
            return (
              <Button
                key={label}
                onClick={() => handleClick(actions, label)}
                label={label}
                variant={variant}
                size={size}
                fullWidth={fullWidth}
                customClassName={customType}
                loading={loading}
                disabled={disabled}
              />
            )
          }
        })}
      </CtaButtonContainer>
    </BaseWidgetComponent>
  )
}

export default CallToActions
