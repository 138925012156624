import React from 'react'
import {MediaInfoWrapper} from './MediaWithInfo.styles'
import {Media, Typography, TypographyVariants} from '@web-components'
import {IMediaWithInfoCard} from './MediaWithInfoCard.interface'

const MediaWithInfoCard = (props: IMediaWithInfoCard) => {
  const {data, aspectRatio} = props
  const {media, content} = data || {}

  const {info = '', bottomContent} = content || {}
  const {name = '', designation = '', number = ''} = bottomContent || {}

  return (
    <MediaInfoWrapper>
      <div className="media-info">
        <div className="media-info-image">
          <Media
            media={media}
            desktopViewWidth={'THIRD'}
            mobileViewWidth={'THIRD'}
            aspectRatio={aspectRatio}
            customClassName="image"
          />
        </div>
        <div className="media-info-content">
          <div className="media-info-description">
            <Typography variant={TypographyVariants.HEADING_MEDIUM_REGULAR}>
              {info}
            </Typography>
          </div>
          <div className="media-info-divider"></div>
          <div className="media-info-bottom">
            <div className="media-info-name">
              <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                {name}
              </Typography>
              <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
                {designation}
              </Typography>
            </div>
            <div className="media-info-number">
              <Typography variant={TypographyVariants.HEADING_MEDIUM_REGULAR}>
                {number}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </MediaInfoWrapper>
  )
}

export default MediaWithInfoCard
